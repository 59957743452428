import PrimaryButton from "../utils/Button";
import Heading from "../utils/typography/Heading";
import Paragraph from "../utils/typography/Paragraph";
import HeaderEmpty from "../utils/HeaderEmpty";
import { GlobalContext } from "../context/Global";
import { useContext, useEffect, useState } from "react";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import base_url, { base_slack_channel } from "../backendUrl";
import SubHeading from "../utils/typography/SubHeading";
import { Radio } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import sendSlackNotification from "../utils/sendSlackNotification";
import getOnboardingStage from "../apis/getOnboardingStage";
import postOnboardingStage from "../apis/postOnboardingStage";

function SelectLocation({ stateChanger, new_state }) {
  let urlParams = new URLSearchParams(window.location.search);
  const [globals] = useContext(GlobalContext);
  const [locationOptions, setLocationOptions] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [urldata, seturlData] = useState([]);
  const new_address = localStorage.getItem("temp_loc");
  const [createGBPLoader, setCreateGBPLoader] = useState(false);

  const email = urlParams.get("email") ?? localStorage.getItem("email") ?? "";
  const pre_uid = urlParams.get("pre_uid") ?? null;
  localStorage["email"] = email;
  const style = {
    position: "absolute",
    padding: "20px",
    // top: "81.5%",
    left: "0",
    right: "0",
    bottom: "0",
    // transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "30px 30px 0 0",
    transition: "transform 0.8s ease-in-out",
    // transform: open ? 'translate(-50%, 0)' : 'translate(-50%, 100%)',
  };

  function isMobileDevice() {
    return true;
    // window.innerWidth <= 768 && window.innerHeight <= 1024;
  }

  const checkLoggedInUser = async (pre_uid) => {
    sendSlackNotification("temp-channel", `checkLoggedInUser - ${pre_uid}`);
    document.getElementById("whole-container").style.display = "none";

    const user_details_url = `${base_url}/chrone_web/get-user-details?uid=${pre_uid}`;
    const user_details_response = await fetch(user_details_url, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    });

    const user_details_resp = await user_details_response.json();
    if (user_details_resp["res"] == "Not a valid user") {
      document.getElementById("whole-container").style.display = "flex";
      // window.location.href = `${window.location.origin}/onboard/priming`;
      await sendSlackNotification("temp-channel", `Not a valid user`);
    } else {
      await sendSlackNotification("temp-channel", `Is a valid user`);

      await sendSlackNotification(
        "temp-channel",
        `localStorage["srcTag"] - ${localStorage["srcTag"]}`
      );

      const item = localStorage["srcTag"];

      await sendSlackNotification(
        "temp-channel",
        `SelectLocation-1 localStorage["srcTag"] - ${localStorage["srcTag"]}`
      );

      const user_data = user_details_resp["res"];
      localStorage["uid"] = user_data["uid"];
      localStorage["location"] = user_data["location"];
      localStorage["place_id"] = user_data["place_id"];
      localStorage["biz_name"] = user_data["biz_name"];
      localStorage["email"] = user_data["email"];

      await sendSlackNotification(
        base_slack_channel,
        `Existing user found\nBiz Name - ${user_data["biz_name"]}\nemail - ${user_data["email"]}\nuid - ${user_data["uid"]}`
      );

      let data = {
        event: item,
        data: localStorage["uid"],
      };

      const onboardingStage = await getOnboardingStage(user_data["uid"]);
      await sendSlackNotification(
        "temp-channel",
        `data after selecting uid ${onboardingStage} - ${JSON.stringify(data)}`
      );
      if (
        onboardingStage &&
        onboardingStage != "completed" &&
        onboardingStage.toLowerCase() != "data not found"
      ) {
        const url = window.location.origin?.replace(/\/onboard/, "");
        window.location.href = `${url}/${onboardingStage}`;
        await sendSlackNotification(
          base_slack_channel,
          `Redirecting to ${onboardingStage}\nBiz Name - ${user_data["biz_name"]}\nemail - ${user_data["email"]}\nuid - ${user_data["uid"]}`
        );
      } else if (
        window.location.href.includes("web.chrone") ||
        localStorage["test_source"] === "web.chrone.io"
      )
        window.location.href = `${window.location.origin}/onboard/select-locations?email=${email}`;
      else {
        window.location.href = `chrone://close-browser?uid=${localStorage["uid"]}`;
      }
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const queryParams = new URLSearchParams(url.search);
    const extractedParams = {};

    if (pre_uid && !window.location.href.includes("web.chrone.io")) {
      checkLoggedInUser(pre_uid);
    }

    for (const [key, value] of queryParams.entries()) {
      extractedParams[key] = value;
    }
    seturlData(extractedParams);
  }, []);

  const getLocationsData = async () => {
    const url = `${base_url}/get_biz_locations?email=${email}`;
    const response = await fetch(url);
    const response_text = await response.text();
    if (response_text == "No locations found") {
      await sendSlackNotification(
        base_slack_channel,
        `OAuth Successful for email: ${email}\n*But no Location Found*`
      );
      window.location.href = `${window.location.origin}/onboard/nobiz?email=${email}`;
    }
    const locations = JSON.parse(response_text);
    console.log(locations);
    setLocationOptions(locations["locations"]);

    if (response_text != "No locations found") {
      var location_string = "";
      for (var i = 0; i < locations["locations"].length; i++) {
        location_string += "  • " + locations["locations"][i]["title"] + "\n";
      }
      sendSlackNotification(
        base_slack_channel,
        `OAuth Successful for email: ${email}\n*Locations Found*:\n${location_string}`
      );
    }
  };
  // Add constraints to block users here after google oauth
  // const checkCountry = async () => {
  //   const country = await sendSlackNotification(
  //     base_slack_channel,
  //     `Email country check: ${email}\n`
  //   );
  //   if (
  //     country == "India" &&
  //     email !== "chroneob@gmail.com" &&
  //     email !== "itsfood.skull@gmail.com"
  //   ) {
  //     window.location.href = `${window.location.origin}/clear`;
  //   }
  // };
  const wait_3_seconds = async () => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res("1");
      }, 3000);
    });
  };
  const onClickNo = async () => {
    localStorage["email"] = email;
    localStorage["temp_loc_name"] = location["name"];
    window.location.href = `${window.location.origin}/SetLocation`;
    setLoader(false);
  };
  const onClickContinue = async () => {
    setOpen(!open);
    mixPanelTrackEvent("location_select_success_click", {
      type: "Click",
      location,
    });
    setLoader(true);
    if (location != undefined) {
      localStorage["location"] = location["name"];
      localStorage["place_id"] = location?.metadata?.placeId;
      localStorage["email"] = email;
      const url = `${base_url}/gmb_save_biz_data_react`;

      const item = localStorage["srcTag"];
      const pre_uid = localStorage["uid"];

      let payload = {
        email: localStorage["email"],
        place_id: localStorage["place_id"],
        location_name: localStorage["location"],
        pd_id: localStorage["pd_id"] ?? "",
      };

      if (window.location.href.includes("web.chrone")) {
        payload["src"] = "Web";
      } else {
        payload["src"] = "App";
      }
      if (pre_uid) {
        payload["pre_uid"] = pre_uid;
      }

      const response = await fetch(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const resp = await response.json();
      console.log(resp["res"]["uid"]);
      const onboardingStage = await getOnboardingStage(resp["res"]["uid"]);
      console.log("RESP", onboardingStage);
      // Check if the user is valid with a pre_uid
      if (resp["error"] == true) {
        alert(resp["res"]);
        setTimeout(() => {
          window.location.href = `${window.location.origin}/clear`;
        }, 3000);
      } else {
        localStorage.setItem("uid", resp["res"]["uid"]);

        if (resp["res"]["new_user"] == true) {
          const mail_url = `${base_url}/gmb_onboarding_mail`;
          const sms_url = `${base_url}/gmb_onboarding_sms`;
          fetch(mail_url, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              location: location["name"].replace("locations/", ""),
              place_id: localStorage["place_id"],
              pd_id: localStorage["pd_id"],
            }),
          });

          fetch(sms_url, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              email: email,
              location: location["name"].replace("locations/", ""),
              place_id: localStorage["place_id"],
              pd_id: localStorage["pd_id"],
            }),
          });
          localStorage["biz_name"] = location["title"];
          sendSlackNotification(
            base_slack_channel,
            `Location Selected: ${location["title"]}\nAddress: ${
              new_address
                ? new_address
                : location?.storefrontAddress?.addressLines?.reduce(
                    (a, b) => a + b,
                    ""
                  )
            }`
          );
          //Waiting for mail and sms api call to be sent but not waiting for the response
          await wait_3_seconds();
          if (
            onboardingStage &&
            onboardingStage != "completed" &&
            onboardingStage.toLowerCase() != "data not found"
          ) {
            const url = window.location.origin?.replace(/\/onboard/, "");
            window.location.href = `${url}/${onboardingStage}`;
          } else {
            const uid = resp["res"]["uid"];
            await postOnboardingStage("onboard/priming", uid, email);
            window.location.href = localStorage["uid"]
              ? `${window.location.origin}/onboard/priming?uid=${localStorage["uid"]}`
              : `${window.location.origin}/onboard/priming`;
          }

          setLoader(false);
        } else {
          const user_details_url = `${base_url}/chrone_web/get-user-details?uid=${resp["res"]["uid"]}`;
          const user_details_response = await fetch(user_details_url, {
            method: "get",
            headers: { "Content-Type": "application/json" },
          });

          const user_details_resp = await user_details_response.json();
          if (user_details_resp["res"] == "Not a valid user") {
            window.location.href = localStorage["uid"]
              ? `${window.location.origin}/onboard/priming?uid=${localStorage["uid"]}`
              : `${window.location.origin}/onboard/priming`;
            const uid = resp["res"]["uid"];
            await postOnboardingStage("onboard/priming", uid, email);
          } else {
            const user_data = user_details_resp["res"];
            localStorage["uid"] = user_data["uid"];
            localStorage["location"] = user_data["location"];
            localStorage["place_id"] = user_data["place_id"];
            localStorage["biz_name"] = user_data["biz_name"];
            localStorage["email"] = user_data["email"];

            const item = localStorage["srcTag"];

            await sendSlackNotification(
              "temp-channel",
              `SelectLocation-2 localStorage["srcTag"] ${onboardingStage} - ${localStorage["srcTag"]}`
            );

            if (
              onboardingStage &&
              onboardingStage != "completed" &&
              onboardingStage.toLowerCase() != "data not found"
            ) {
              const url = window.location.origin?.replace(/\/onboard/, "");
              window.location.href = `${url}/${onboardingStage}`;
            } else if (window.location.href.includes("web.chrone"))
              window.location.href = `${base_url}/old-dashboard-transit?place_id=${localStorage["place_id"]}&uid=${localStorage["uid"]}&location=${localStorage["location"]}`;
            else {
              window.location.href = `chrone://close-browser?uid=${localStorage["uid"]}`;
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    mixPanelTrackEvent("user_priming_select_location_screen_open", {
      type: "Page Open",
    });
    if (email != "") {
      getLocationsData();
    } else {
      alert("Email login error!");
      window.location.href = `${window.location.origin}/onboard`;
    }
  }, []);

  return (
    <div
      id="whole-container"
      style={{
        height: globals.height,
        display: "flex",
        flexDirection: "column-reverse",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          // overflowX: "hidden",
          overflowY: "hidden",
          marginBottom: "100px",
          marginTop: "20px",
        }}>
        <div
          style={{
            backgroundSize: "cover",
            flexGrow: 1,
            overflowY: "scroll",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}>
          <div>
            {locationOptions &&
              locationOptions.map((loc) => (
                <div
                  key={loc["name"]}
                  className="location-box"
                  style={{
                    borderRadius: "20px",
                    borderWidth: "1.5px",
                    borderStyle: "solid",
                    borderColor:
                      location && location["name"] == loc["name"]
                        ? "#C14296"
                        : "#CED0D0",
                    marginBottom: "16px",
                    padding: "0px 10px 10px 10px",
                  }}
                  onClick={() => {
                    mixPanelTrackEvent("location_radio_btn_click", {
                      type: "Click",
                      loc: loc["name"],
                    });
                    setLocation(loc);
                  }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>
                    <SubHeading>{loc["title"]}</SubHeading>
                    <Radio
                      style={{
                        color:
                          location && loc["name"] == location["name"]
                            ? "#C14296"
                            : "#0C1414",
                        paddingBottom: "5px",
                      }}
                      checked={
                        location && loc["name"] == location["name"]
                          ? true
                          : false
                      }></Radio>
                  </div>
                  <Paragraph style={{ color: "#555B5B" }}>
                    {new_address && localStorage["temp_loc_name"] == loc["name"]
                      ? new_address
                      : loc?.storefrontAddress?.addressLines?.reduce(
                          (a, b) => a + b,
                          ""
                        )}
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                    }}>
                    <div
                      style={{
                        color: loc["verified"] ? "#26A780" : "#BD463E",
                        fontFamily: "Red Hat Display",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "12px",
                        marginRight: "5px",
                      }}>
                      {loc["verified"] ? "Verified" : "Not Verified"}
                    </div>
                    {loc["verified"] ? (
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.00002 11.8332C5.17363 11.8332 4.40558 11.6849 3.69585 11.3884C2.98613 11.0919 2.36877 10.6811 1.84377 10.1561C1.31877 9.63109 0.908006 9.01373 0.611479 8.30401C0.314951 7.59428 0.166687 6.82623 0.166687 5.99984C0.166687 5.18317 0.314951 4.41998 0.611479 3.71025C0.908006 3.00053 1.31877 2.38317 1.84377 1.85817C2.36877 1.33317 2.98613 0.919976 3.69585 0.618587C4.40558 0.317198 5.17363 0.166504 6.00002 0.166504C6.72919 0.166504 7.40974 0.283171 8.04169 0.516504C8.67363 0.749837 9.24238 1.07067 9.74794 1.479L9.12085 2.10609C8.69308 1.76581 8.21669 1.50331 7.69169 1.31859C7.16669 1.13387 6.6028 1.0415 6.00002 1.0415C4.5903 1.0415 3.41148 1.51546 2.46356 2.46338C1.51565 3.4113 1.04169 4.59012 1.04169 5.99984C1.04169 7.40956 1.51565 8.58838 2.46356 9.5363C3.41148 10.4842 4.5903 10.9582 6.00002 10.9582C7.40974 10.9582 8.58856 10.4842 9.53648 9.5363C10.4844 8.58838 10.9584 7.40956 10.9584 5.99984C10.9584 5.70817 10.9365 5.4238 10.8927 5.14671C10.849 4.86963 10.7834 4.59984 10.6959 4.33734L11.3667 3.6665C11.5222 4.02623 11.6389 4.40053 11.7167 4.78942C11.7945 5.17831 11.8334 5.58178 11.8334 5.99984C11.8334 6.82623 11.6827 7.59428 11.3813 8.30401C11.0799 9.01373 10.6667 9.63109 10.1417 10.1561C9.61669 10.6811 8.99933 11.0919 8.2896 11.3884C7.57988 11.6849 6.81669 11.8332 6.00002 11.8332ZM5.1396 8.65401L2.73335 6.23317L3.3896 5.57692L5.1396 7.32692L11.1771 1.28942L11.8479 1.94567L5.1396 8.65401Z"
                          fill="#26A780"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M5.99976 8.91699C6.13604 8.91699 6.25037 8.8709 6.34273 8.77871C6.43509 8.68652 6.48127 8.57228 6.48127 8.436C6.48127 8.29972 6.43518 8.1854 6.34299 8.09303C6.2508 8.00067 6.13656 7.95449 6.00028 7.95449C5.864 7.95449 5.74967 8.00059 5.65731 8.09277C5.56495 8.18497 5.51877 8.2992 5.51877 8.43548C5.51877 8.57177 5.56486 8.68609 5.65705 8.77845C5.74925 8.87081 5.86348 8.91699 5.99976 8.91699ZM5.60627 6.68574H6.48127V2.99616H5.60627V6.68574ZM6.0039 11.8337C5.19954 11.8337 4.44364 11.6805 3.73619 11.3743C3.02874 11.068 2.41009 10.65 1.88023 10.1201C1.35037 9.59026 0.932312 8.97124 0.626062 8.26306C0.319812 7.55489 0.166687 6.79822 0.166687 5.99303C0.166687 5.18785 0.319812 4.43117 0.626062 3.72301C0.932312 3.01483 1.35037 2.39824 1.88023 1.87324C2.41009 1.34824 3.02911 0.932617 3.73728 0.626367C4.44545 0.320117 5.20213 0.166992 6.00731 0.166992C6.8125 0.166992 7.56917 0.320117 8.27734 0.626367C8.98552 0.932617 9.6021 1.34824 10.1271 1.87324C10.6521 2.39824 11.0677 3.0156 11.374 3.72533C11.6802 4.43505 11.8334 5.19209 11.8334 5.99645C11.8334 6.8008 11.6802 7.55671 11.374 8.26415C11.0677 8.9716 10.6521 9.58936 10.1271 10.1174C9.6021 10.6455 8.98474 11.0636 8.27502 11.3716C7.5653 11.6796 6.80826 11.8337 6.0039 11.8337ZM6.00731 10.9587C7.38301 10.9587 8.5521 10.475 9.5146 9.50762C10.4771 8.54026 10.9584 7.36873 10.9584 5.99303C10.9584 4.61734 10.478 3.44824 9.51735 2.48574C8.55666 1.52324 7.38422 1.04199 6.00002 1.04199C4.62919 1.04199 3.46009 1.52233 2.49273 2.483C1.52537 3.44368 1.04169 4.61612 1.04169 6.00033C1.04169 7.37116 1.52537 8.54026 2.49273 9.50762C3.46009 10.475 4.63162 10.9587 6.00731 10.9587Z"
                          fill="#BD463E"
                        />
                      </svg>
                    )}
                  </div>
                </div>
              ))}
            {locationOptions && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "30px",
                  fontFamily: "Red Hat Display",
                }}>
                Can't find what you are looking for?
                <div
                  style={{
                    padding: "12px 14px",
                    width: createGBPLoader ? "180px" : "150px",
                  }}
                  onClick={async () => {
                    setCreateGBPLoader(true);
                    await sendSlackNotification(
                      base_slack_channel,
                      "User selected Create GBP"
                    );
                    window.location.href = `/onboard/create-gbp?email=${email}`;
                  }}>
                  <Paragraph
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      color: "#B03D8C",
                      fontWeight: "800",
                      fontSize: "15px",
                      cursor: "pointer",
                      textAlign: "center",
                    }}>
                    {" "}
                    Create GBP
                    {createGBPLoader && (
                      <CircularProgress
                        style={{
                          color: "#B03D8C",
                          height: "16px",
                          width: "16px",
                          marginLeft: "5px",
                        }}
                      />
                    )}
                  </Paragraph>
                </div>
              </div>
            )}
          </div>
          {locationOptions && locationOptions.length == 0 ? (
            <div>
              No Business location is linked to this mail account.{" "}
              <div
                onClick={async () => {
                  const oldSrcTag = localStorage.getItem("srcTag");
                  localStorage.clear();
                  if (oldSrcTag != null) {
                    localStorage.setItem("srcTag", oldSrcTag);
                  }
                  await sendSlackNotification(
                    base_slack_channel,
                    "Locations page - Try another email clicked"
                  );
                  window.location.href = "/onboard";
                }}>
                <Paragraph
                  style={{
                    color: "#000000",
                    fontWeight: "800",
                    fontSize: "15px",
                    cursor: "pointer",
                    textAlign: "left",
                  }}>
                  Try another email
                </Paragraph>
              </div>
            </div>
          ) : null}
          {locationOptions == undefined && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <CircularProgress style={{ color: "#AA4198" }} />
            </div>
          )}
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0",
            width: globals.width,
            // margin: "0 20px",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}>
            <div
              onClick={async () => {
                const oldSrcTag = localStorage.getItem("srcTag");
                localStorage.clear();
                if (oldSrcTag != null) {
                  localStorage.setItem("srcTag", oldSrcTag);
                }
                await sendSlackNotification(
                  base_slack_channel,
                  "Location page - Try another email clicked"
                );
                window.location.href = "/onboard";
              }}>
              <Paragraph
                style={{
                  color: "#000000",
                  fontWeight: "800",
                  fontSize: "15px",
                  cursor: "pointer",
                  padding: "1rem 0rem",
                  textAlign: "center",
                }}>
                Try another email
              </Paragraph>
            </div>
            <PrimaryButton
              style={{
                cursor: "pointer",
                width: "180px",
                padding: "16px 12px",
              }}
              onClick={() => {
                localStorage["email"] = email;
                console.log("Current location:", location);
                handleOpen();
              }}
              disabled={location === undefined || loader}>
              <>
                CONTINUE
                {loader ? (
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                      color: "#000000",
                    }}
                  />
                ) : null}
              </>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <div className="side-padding">
        <Heading style={{ paddingTop: 0, paddingBottom: 0 }}>
          Select your google business profile
        </Heading>
      </div>
      <HeaderEmpty style={{ padding: "20px", paddingBottom: "14px" }} />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
        }}>
        <Box sx={style}>
          <CloseIcon onClick={handleClose} />
          <Heading
            style={{ paddingBottom: 2, textAlign: "left", paddingTop: 2 }}>
            Is your business location correct?
          </Heading>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "5px",
              alignItems: "center",
              paddingBottom: 4,
              paddingTop: 2,
            }}>
            <FmdGoodIcon style={{ fontSize: "30px", color: "#555B5B" }} />
            <Paragraph
              style={{ color: "#555B5B", paddingBottom: 2, paddingTop: 2 }}>
              {new_address
                ? new_address
                : location?.storefrontAddress?.addressLines?.reduce(
                    (a, b) => a + b,
                    ""
                  )}
            </Paragraph>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "start",
              gap: "1rem",
              cursor: "pointer",
            }}>
            <div
              onClick={onClickNo}
              style={{
                backgroundColor: "#F3F4F5",
                borderRadius: "999px",
                padding: "18px 40px",
                fontSize: "16px",
                color: "#0C1414",
                fontWeight: "800",
              }}>
              <SubHeading
                style={{ paddingTop: 0, paddingBottom: 0, color: "#0C1414" }}>
                No
              </SubHeading>
            </div>

            <div
              onClick={onClickContinue}
              style={{
                backgroundColor: "#0C1414",
                borderRadius: "999px",
                padding: "18px 40px",
                fontSize: "16px",
                color: "#fff",
                fontWeight: "800",
                cursor: "pointer",
              }}>
              <SubHeading
                style={{ paddingTop: 0, paddingBottom: 0, color: "#fff" }}>
                Yes
              </SubHeading>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default SelectLocation;
