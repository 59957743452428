import React from "react";

import { ServicesContextProvider } from "./context/context";
import Services from "./App";

export default function Index() {
  return (
    <ServicesContextProvider>
        <Services />
    </ServicesContextProvider>
  );
}