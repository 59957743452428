import HeaderBack from "../utils/HeaderBack";
import Heading from "../utils/typography/Heading";
import Paragraph from "../utils/typography/Paragraph";
import { Container } from "@mui/system";
import FixedBottom from "../utils/FixedBottom";
import SearchBar from "../utils/SearchBar";
import { Divider, Grid, Stack } from "@mui/material";
import ServiceCard from "./ServiceCard";
import base_url from "../backendUrl";
import { ServicesContext } from "./context/context";
import { useContext, useState, useEffect } from "react";
import SimpleSnackbar from "../utils/SnackBar";
import GoogleButton from "../utils/GoogleButton";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import SubHeading from "../utils/typography/SubHeading";
import CircularProgress from "@mui/material/CircularProgress";

function ServicesList() {
  let nextEnabled = false;
  const [serviceStates, setServiceStates] = useContext(ServicesContext);
  const [searchValue, setSearchValue] = useState("");
  const [services, setServices] = useState([]);
  const [loader, setLoader] = useState(false);

  const get_services = async () => {
    const service_response = await fetch(url, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    });

    service_response.json().then((data) =>
      setServiceStates({
        action: "services_name",
        services_name: data.services,
      })
    );
  };

  let service = {};
  service["place_id"] = localStorage.getItem("place_id");
  const primary_category =
    localStorage.getItem("primary_category") == null
      ? "Barber"
      : localStorage.getItem("primary_category");
  service["category"] = primary_category;
  service["star"] = false;
  service["currency"] = "USD";
  service["pricing_type"] = "STARTS_FROM";
  const url = base_url + "/get_ss_services/" + primary_category;

  console.log(serviceStates.services);
  serviceStates.services.map((e) => {
    if (e.star == true) {
      nextEnabled = true;
    }
  });

  const fetchCategories = async () => {
    const url = base_url + "/get_ss_category";
    try {
      const response = await fetch(url);
      const json = await response.json();
      let result = json.res;
      setServiceStates({
        action: "set_categories",
        categories: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onClickNext = () => {
    mixPanelTrackEvent("service_added_done_click", {
      type: "Click",
    });
    if (nextEnabled === false) return;
    window.location.href = `${window.location.origin}/setNumber`;
  };

  const updateService = async (service) => {
    setServiceStates({
      action: "change_screen_snackbar",
      screen_no: serviceStates.prev,
      showSnackbar: true,
      services: serviceStates.services,
    });

    console.log(service);
    let payload = service;
    const url = base_url + "/update-service";

    const response = await fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
  };

  const deleteService = async (service) => {
    setServiceStates({
      action: "change_screen_snackbar",
      screen_no: 1,
      showSnackbar: true,
      services: serviceStates.services.filter(
        (e) => e.service_id != service.service_id
      ),
    });

    let payload = { service_id: service.service_id };
    console.log(payload);
    const url = base_url + "/delete-service";

    const response = await fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
  };

  const starservice = async (serviceID, star) => {
    let services = serviceStates.services.map((serv) =>
      serv.service_id !== serviceID
        ? serv
        : {
            ...serv,
            star,
          }
    );

    setServiceStates({
      action: "init_services",
      services: services,
    });

    let payload = {
      service_id: serviceID,
      star: star,
    };

    const url = base_url + "/star-service";

    const response = await fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
  };

  const requestSearch = (searchedVal) => {
    setSearchValue(searchedVal.target.value);
  };

  useEffect(() => {
    get_services();
  }, []);

  const rows =
    searchValue == ""
      ? serviceStates.services
      : serviceStates.services.filter((service) => {
          return service.name
            .toLowerCase()
            .startsWith(searchValue.toLowerCase())
            ? true
            : false;
        });

  const categories = [...new Set(rows.map((e) => e["category"]))];

  const ServiceItems = categories.map((category) => {
    const services = rows.filter((e) => e["category"] == category);
    return (
      <div>
        <Heading paddingTop={"24px"}>{category}</Heading>
        {services.map((service, idx) => (
          <ServiceCard
            key={idx}
            starservice={starservice}
            updateService={updateService}
            service={service}
            deleteService={deleteService}
          />
        ))}
      </div>
    );
  });

  let service_new = {};
  service_new["place_id"] = localStorage.getItem("place_id");
  service_new["star"] = false;
  service_new["currency"] = "USD";
  service_new["pricing_type"] = "STARTS_FROM";

  useEffect(() => {
    fetchCategories();

    mixPanelTrackEvent("added_services_screen_open", {
      type: "Page Open",
    });
  }, []);

  const clickFunc = (e) => {
    console.log(e.target.dataset.value);
    service["name"] = e.target.dataset.value;
    setServiceStates({
      action: "change_screen_service",
      screen_no: 5,
      service: service,
    });
  };

  return (
    <div style={{ marginBottom: "160px" }}>
      <HeaderBack
        back={() => setServiceStates({ action: "change_screen", screen_no: 0 })}
        backColor={"#F0F0F0"}
        style={{}}
      />
      <Container style={{ paddingBottom: "150px" }}>
        <Heading style={{ paddingTop: "32px" }}>
          Star the services on which you want more leads
        </Heading>
        <Paragraph style={{ fontSize: "16px", lineHeight: "24px" }}>
          We use it for your google profile completion, website and get you
          leads
        </Paragraph>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>
          <SearchBar onChange={(searchVal) => requestSearch(searchVal)} />
          <GoogleButton
            onClick={() => {
              mixPanelTrackEvent("add_service_click", {
                type: "Click",
              });
              setServiceStates({
                action: "change_screen_service",
                screen_no: 6,
              });
              setLoader(true);
            }}
            style={{
              width: "125px",
              height: "44px",
              marginTop: "14px",
              padding: 0,
            }}>
            <>
              Add Service
              {loader ? (
                <CircularProgress
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                    color: "#AA4198",
                  }}
                />
              ) : null}
            </>
          </GoogleButton>
        </Grid>
        <div
          style={{
            paddingBottom: "50px",
          }}>
          {ServiceItems.length ? (
            ServiceItems
          ) : (
            <div
              style={{
                fontFamily: "Red Hat Display",
                marginTop: "30px",
              }}>
              {searchValue && searchValue != ""
                ? "No services matched with your input"
                : "Add services"}
            </div>
          )}
        </div>
        <Divider
          style={{ marginBottom: "40px" }}
          orientation="horizontal"
          flexItem
        />

        <Heading>Recommended services</Heading>

        {primary_category != "Barber" ? (
          <Paragraph style={{ fontSize: "16px", lineHeight: "24px" }}>
            Based on {primary_category} as your primary category
          </Paragraph>
        ) : null}

        <Stack mt={4} spacing={3}>
          {serviceStates.services_name.map(function (service_n) {
            return (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}>
                <Paragraph
                  style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: 600,
                    color: "black",
                  }}>
                  {service_n}
                </Paragraph>
                <button
                  style={{ border: "none", background: "transparent" }}
                  onClick={clickFunc}>
                  <SubHeading value={service_n}>ADD</SubHeading>
                </button>
              </Stack>
            );
          })}
        </Stack>
      </Container>

      <FixedBottom
        nextEnabled={nextEnabled}
        onClickNext={onClickNext}
        buttonText="DONE"
        fillPercent={80}
      />
      <SimpleSnackbar
        show={serviceStates.showSnackbar}
        setSnackbar={setServiceStates}
      />
    </div>
  );
}

export default ServicesList;
