import base_url from "../backendUrl";

const sendSlackNotification = async (channel, message) => {
  let source = "App";
  let log_channel = channel;
  if (window.location.href.includes("https://dirxdqvvyztlm.cloudfront.net")) {
    log_channel = "chrone-io-dev";
  }
  if (window.location.href.includes("web.chrone")) {
    source = "Web";
  }
  let data = {
    channel: log_channel,
    message: `========================================================\n\nChrone.io\n\n${
      localStorage["email"] ? `\nEmail: ${localStorage["email"]}` : ""
    }\n${message}\nsource: ${source}`,
  };
  try {
    const resp = await fetch(`${base_url}/send-slack`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    });
    const countryData = await resp.json();
    return countryData["country"];
  } catch (e) {
    console.log(e);
  }
};

export default sendSlackNotification;
