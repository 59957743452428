import HeaderBack from "../utils/HeaderBack";
import connectSquare from "../assets/img/connect-square.svg";
import connectInstagram from "../assets/img/connect-instagram.svg";
import googleReviews from "../assets/img/google-reviews.svg";
import { useNavigate } from "react-router-dom";

function Banner({
  stateChanger,
  new_state,
  backColor,
  showGoogleVariant = false,
  takeBack,
  showSquareVariant = false,
  showInstaVariant = false,
}) {
  const change = () => stateChanger(new_state);
  if (!showSquareVariant) {
    showSquareVariant = new_state === 0 || new_state === 1;
  }

  if (!showInstaVariant) {
    showInstaVariant = new_state === 2;
  }
  const navigate = useNavigate();

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}>
      <div>
        <HeaderBack
          back={() => {
            if (new_state) {
              change();
            } else {
              navigate(-1);
            }
          }}
          backColor={backColor}
          style={{}}
        />
      </div>
      <div className="section" style={{}}>
        {showSquareVariant && (
          <img className="connect-square" src={connectSquare} alt="error" />
        )}
        {showInstaVariant && (
          <img
            className="connect-instagram"
            src={connectInstagram}
            alt="error"
          />
        )}
        {showGoogleVariant && (
          <img className="connect-instagram" src={googleReviews} alt="error" />
        )}
      </div>
    </div>
  );
}

export default Banner;
