import React from 'react';
import { GlobalContext } from '../context/Global';
import { useContext, useState } from "react";
import Heading from '../utils/typography/Heading';
import InputField from '../utils/InputField';
import CloseIcon from '@mui/icons-material/Close';
import Paragraph from '../utils/typography/Paragraph';
import { useNavigate } from "react-router-dom"
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const inlineStyle = {
    background: 'linear-gradient(202.4deg, #F5448F -43.81%, #263AA7 182.55%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    fontWeight: 800
};


const SetSelectLocation = () => {
    const [globals] = useContext(GlobalContext);
    const [address, setAdrress] = useState("");
    const [Town, setTown] = useState("");
    const [pincode, setPinCode] = useState("");
    const [state, setState] = useState("State");
    const [loader, setLoader] = useState(false);
    const [country, setCountry] = useState('USA'); 
    const navigate = useNavigate()

  const convertedCountry = [
    { label: 'United Kingdom', value: 'UK' },
    { label: 'United States', value: 'USA' },
    { label: 'Canada', value: 'CAN' }
  ];

  
   
    const stateOptions ={
        "UK": [
          {"label": "England", "value": "England"},
          {"label": "Scotland", "value": "Scotland"},
          {"label": "Wales", "value": "Wales"},
          {"label": "Northern Ireland", "value": "Northern Ireland"}
        ],
        "USA": [
          {"label": "Alabama", "value": "Alabama"},
          {"label": "Alaska", "value": "Alaska"},
          {"label": "Arizona", "value": "Arizona"},
          {"label": "Arkansas", "value": "Arkansas"},
          {"label": "California", "value": "California"},
          {"label": "Colorado", "value": "Colorado"},
          {"label": "Connecticut", "value": "Connecticut"},
          {"label": "Delaware", "value": "Delaware"},
          {"label": "Florida", "value": "Florida"},
          {"label": "Georgia", "value": "Georgia"},
          {"label": "Hawaii", "value": "Hawaii"},
          {"label": "Idaho", "value": "Idaho"},
          {"label": "Illinois", "value": "Illinois"},
          {"label": "Indiana", "value": "Indiana"},
          {"label": "Iowa", "value": "Iowa"},
          {"label": "Kansas", "value": "Kansas"},
          {"label": "Kentucky", "value": "Kentucky"},
          {"label": "Louisiana", "value": "Louisiana"},
          {"label": "Maine", "value": "Maine"},
          {"label": "Maryland", "value": "Maryland"},
          {"label": "Massachusetts", "value": "Massachusetts"},
          {"label": "Michigan", "value": "Michigan"},
          {"label": "Minnesota", "value": "Minnesota"},
          {"label": "Mississippi", "value": "Mississippi"},
          {"label": "Missouri", "value": "Missouri"},
          {"label": "Montana", "value": "Montana"},
          {"label": "Nebraska", "value": "Nebraska"},
          {"label": "Nevada", "value": "Nevada"},
          {"label": "New Hampshire", "value": "New Hampshire"},
          {"label": "New Jersey", "value": "New Jersey"},
          {"label": "New Mexico", "value": "New Mexico"},
          {"label": "New York", "value": "New York"},
          {"label": "North Carolina", "value": "North Carolina"},
          {"label": "North Dakota", "value": "North Dakota"},
          {"label": "Ohio", "value": "Ohio"},
          {"label": "Oklahoma", "value": "Oklahoma"},
          {"label": "Oregon", "value": "Oregon"},
          {"label": "Pennsylvania", "value": "Pennsylvania"},
          {"label": "Rhode Island", "value": "Rhode Island"},
          {"label": "South Carolina", "value": "South Carolina"},
          {"label": "South Dakota", "value": "South Dakota"},
          {"label": "Tennessee", "value": "Tennessee"},
          {"label": "Texas", "value": "Texas"},
          {"label": "Utah", "value": "Utah"},
          {"label": "Vermont", "value": "Vermont"},
          {"label": "Virginia", "value": "Virginia"},
          {"label": "Washington", "value": "Washington"},
          {"label": "West Virginia", "value": "West Virginia"},
          {"label": "Wisconsin", "value": "Wisconsin"},
          {"label": "Wyoming", "value": "Wyoming"}
        ],
        "CAN": [
          {"label": "Alberta", "value": "Alberta"},
          {"label": "British Columbia", "value": "British Columbia"},
          {"label": "Manitoba", "value": "Manitoba"},
          {"label": "New Brunswick", "value": "New Brunswick"},
          {"label": "Newfoundland and Labrador", "value": "Newfoundland and Labrador"},
          {"label": "Northwest Territories", "value": "Northwest Territories"},
          {"label": "Nova Scotia", "value": "Nova Scotia"},
          {"label": "Nunavut", "value": "Nunavut"},
          {"label": "Ontario", "value": "Ontario"},
          {"label": "Prince Edward Island", "value": "Prince Edward Island"},
          {"label": "Quebec", "value": "Quebec"},
          {"label": "Saskatchewan", "value": "Saskatchewan"},
          {"label": "Yukon", "value": "Yukon"}
        ]
      }
      
    return (
        <div style={{
            height: globals.height,
            width: globals.width,
            backgroundSize: "contain",
            margin: "auto",
            padding: "0 20px"
        }}>
            <CloseIcon
                onClick={() => {
                    navigate(-1);
                }}
                style={{ margin: "20px 0" }} />
            <Heading style={{ paddingBottom: 5 }}>
                Change your business location
            </Heading>

            <InputField label='Street Address'
                defaultValue=''
                onChange={(e) => {
                    setAdrress(e.target.value)
                }}

            />
            <Paragraph style={inlineStyle}>
                Add address line (optional)
            </Paragraph>
            <InputField label='Town/City'
                defaultValue=''
                onChange={(e) => {
                    setTown(e.target.value);
                }}

            />
            <InputField label='PinCode'
                defaultValue=''
                onChange={(e) => {
                    setPinCode(e.target.value);
                }}

            />

            
            <div
                style={{
                    border: "1px solid #CED0D0",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    autoWidth
                    label={null}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            border: "none",
                            "&:hover": {
                                border: "none"
                            },
                            "&.Mui-focused": {
                                border: "none"
                            }
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none"
                        },
                        width: "100%",
                        color: "#000"
                    }}
                >
                    {convertedCountry.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>
            </div>
            <div
                style={{
                    border: "1px solid #CED0D0",
                    borderRadius: "10px",
                    margin: "20px 0",
                }}
            >
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={state}
                    onChange={(e) => { setState(e.target.value) }}
                    autoWidth
                    label={null}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            border: "none",
                            "&:hover": {
                                border: "none"
                            },
                            "&.Mui-focused": {
                                border: "none"
                            }
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            border: "none"
                        },
                        width: "100%"
                    }}
                >
                    {stateOptions[country].map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))}
                </Select>

            </div>
            <div
                className="continue"
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    position: "fixed",
                    bottom: "0",
                    left: 0,
                }}
            >
                <div
                    className='btn-cls'
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        const email = localStorage.getItem("email")
                        const locationData = address + " " + Town + " " + state + " " + country + " " + pincode
                        localStorage.setItem("temp_loc", locationData)
                        window.location.href = `${window.location.origin}/onboard/select-locations?email=${email}`
                        setLoader(false);
                    }}
                >
                    Change location
                </div>
            </div>

        </div>
    )
}

export default SetSelectLocation