import React, { useState } from "react";
import FixedBottom from "../utils/FixedBottom";
import Heading from "../utils/typography/Heading";
import HeaderBack from "../utils/HeaderBack";
import sendSlackNotification from "../utils/sendSlackNotification";
import base_url, { base_slack_channel } from "../backendUrl";
import axios from "axios";
import postOnboardingStage from "../apis/postOnboardingStage";
import OptionBox from "../utils/OptionBox";

export default function SquareUsage({ stateChanger }) {
  const [selectedOption, setSelectedLocation] = useState("");
  const [loading, setLoading] = useState(false);
  const options = [
    { heading: "Yes, I use Square for bookings!", option: "yes-square" },
    { heading: "No, but I use it for payments", option: "payments-square" },
    { heading: "No. I don't use Square at all", option: "no-square" },
  ];

  const handleSelectOption = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleOnClickDone = async () => {
    setLoading(true);
    try {
      postSquarePreference(selectedOption);
      if (selectedOption !== "no-square") {
        await postOnboardingStage(
          "onboard/priming?state-2",
          localStorage["uid"],
          localStorage["email"]
        );
        // if (window.location.href.includes("web.chrone.io")) {
        //   window.location.href = "/services";
        // } else {
        stateChanger(2);
        // }
      } else {
        //Make the next step here stateChanger(3) when integrating instagram
        await postOnboardingStage(
          "services",
          localStorage["uid"],
          localStorage["email"]
        );
        window.location.href = localStorage["uid"]
          ? `/services?uid=${localStorage["uid"]}`
          : "/services";
      }
      const item = options.find((item) => item.option === selectedOption);
      const optionSelectedHeading = item
        ? item.heading
        : "Developer log: Square Usage: No such option found!";
      await sendSlackNotification(base_slack_channel, optionSelectedHeading);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      await sendSlackNotification(
        base_slack_channel,
        "Developer log: Error in square usage screen"
      );
      window.location.href = localStorage["uid"]
        ? `/services?uid=${localStorage["uid"]}`
        : "/services";
    }
  };

  const postSquarePreference = (preference) => {
    axios.post(`${base_url}/square-preference`, {
      square_preference: preference,
      uid: localStorage["uid"],
    });
  };

  return (
    <div style={{ paddingLeft: "16px", paddingRight: "16px" }}>
      <Heading mt={4}>Do you use Square appointments?</Heading>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5vh" }}>
        <OptionBox
          handleSelectOption={handleSelectOption}
          selectedOption={selectedOption}
          options={options}
        />
      </div>

      <FixedBottom
        loader={loading}
        nextEnabled={selectedOption !== "" ? true : false}
        onClickNext={handleOnClickDone}
        buttonText="NEXT"
        fillPercent={20}
      />
    </div>
  );
}
