import PrimaryButton from "../utils/Button";
import Heading from "../utils/typography/Heading";
import priming_start from "../assets/img/priming_start.webp";
import customer from "../assets/img/customer.png";
import Paragraph from "../utils/typography/Paragraph";
import HeaderEmpty from "../utils/HeaderEmpty";
import FixedBottom from "../utils/FixedBottom";
import { GlobalContext } from "../context/Global";
import { useContext, useEffect } from "react";
import { mixPanelTrackEvent } from "../utils/mixpanel";

function Priming({ stateChanger, new_state }) {
  const [globals] = useContext(GlobalContext);

  useEffect(() => {
    mixPanelTrackEvent("user_priming_screen_open", {
      type: "Page Open",
    });
  }, []);

  return (
    <div
      style={{
        height: globals.height,
        display: "flex",
        flexDirection: "column-reverse",
        justifyContent: "space-between",
      }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}>
        <div
          className="hair"
          style={{
            backgroundImage: `url(${priming_start})`,
            backgroundSize: "cover",
            flexGrow: 1,
          }}
        />
        <div
          style={{
            height: "75px",
            background: "linear-gradient(1.5turn, #FFFFFF02, #FFFFFFFF)",
          }}
        />
        <div className="got-it-button">
          <PrimaryButton
            style={{ cursor: "pointer" }}
            disabled={false}
            onClick={() => {
              mixPanelTrackEvent("connection_priming_success_click", {
                type: "Click",
              });
              stateChanger(new_state);
            }}>
            GOT IT
          </PrimaryButton>
        </div>
      </div>
      <div className="side-padding">
        <Heading style={{ paddingTop: 0, paddingBottom: 0 }}>
          Reviews & Photos takes your ranking up, Chrone helps in automating it
        </Heading>
      </div>
      <HeaderEmpty style={{ padding: "20px", paddingBottom: "14px" }} />
    </div>
  );
}

export default Priming;
