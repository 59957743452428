import { FormControl } from "@mui/material";
import InputField from "../utils/InputField";
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import {Container} from "@mui/material";
import PrimaryButton from "../utils/Button";
import { useState, useContext } from "react";
import DropdownSearch from "../utils/DropdownSearch";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { ServicesContext } from "./context/context";

const time = [
    {value:'15',
     label : '15 mins'
    },
    {value:'30',
     label : '30 mins'
    },
    {value:'45',
     label : '45 mins'
    },
    {value:'60',
     label : '60 mins'
    }
]



const getCurrencySymbol = (currency) => {
    const map = {
        "USD": "$"
    };

    return map[currency];
}

export default function ServiceAddForm({text, service, updateService}){
    const [serviceStates, setServiceStates] = useContext(ServicesContext)

    let category_list = [];

    const [enabled, setEnabled] = useState(false);
    // console.log(categories);
    serviceStates.categories.map((e)=>{category_list.push({value : e, label : e})});

    let options  = category_list.map((i)=>i.value);

    service["price_start"] = (service["price_start"] || '')
    service["description"] = (service["description"] || '')
    service["name"] = (service["name"] || '');
    service["category"] = (service["category"] || '');

    const setPriceValue = (e) => {
        service["price_start"] = (e.target.value)
    }

    const setName = (e) => {
        service["name"] = (e.target.value);
        if(service["name"]!='' && service["category"]!='') setEnabled(true);
        if(service["name"]=='' || service["category"]=='') setEnabled(false);
        console.log(enabled)
        mixPanelTrackEvent('category_selected', {
            type: 'Click',
            category: e
        })
    }

    const setCategory = (e) => {
        service["category"] = (e);
        if(service["name"]!='' && service["category"]!='') setEnabled(true);
        if(service["name"]=='' || service["category"]=='') setEnabled(false);
        console.log(service["category"])
        mixPanelTrackEvent('category_selected', {
            type: 'Click',
            category: e
        })
    }

    const setDescription = (e) => {
        service["description"] = (e.target.value)
    }

    const set = () => {
        if(enabled===false) return;
        updateService(service);
    }
    
    return(
        <FormControl variant="standard" style={{width:'100%'}}>
            
            <DropdownSearch option={options} label='Service category' setCategory={setCategory} name='category' id='category'  />

            {/* <InputField select label='service category' onChange={setCategory} name='category' >
                {category_list.map((option) => (
                    <MenuItem className="menu-item" key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </InputField> */}

            <InputField label='service name'  onChange={setName}  name='name'   defaultValue={service.name} />

            <InputField onClick={() => {
                mixPanelTrackEvent('service_description_input_click', {
                    type: 'Click'
                })
            }} onChange={setDescription} name='description' multiline label='Service description' defaultValue={service.description} />

            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <InputField select label='Service duration' defaultValue='15' >
                        {time.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </InputField>
                </Grid>
                <Grid item xs={6} md={6}>
                    <InputField
                        type="number"
                        label='Service price'
                        defaultValue={service['price_start']}
                        onChange={setPriceValue}
                        className="input-price"
                        onClick={() => {
                            mixPanelTrackEvent('service_price_input_click', {
                                type: 'Click'
                            })
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{getCurrencySymbol(service['currency'])}</InputAdornment>,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                </Grid>
                <Grid item xs={6} md={6}>
                    <div style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                        <Checkbox defaultChecked={service['pricing_type']==='STARTS_FROM'} style={{color:'#000000'}} onChange={() => {
                            mixPanelTrackEvent('service_price_up_click', {
                                type: 'Click',
                                case: e.target.checked
                            })
                            service['pricing_type'] = e.target.checked ? "STARTS_FROM" : "FIXED"
                        }} />
                        <div style={{fontSize: "Red hat display"}}>& up</div>
                    </div>
                </Grid>
            </Grid>


            <Container sx={{ position: 'fixed', background: 'white', bottom: '20px',left : 0 }}>
                <PrimaryButton enabled={enabled}  onClick={() => {
                    mixPanelTrackEvent('service_submit_click', {
                        type: 'Click',
                        ...service
                    })
                    set()
                }} style={{ margin: 'auto', width: '175px' }}>{text}</PrimaryButton>
            </Container>

        </FormControl>

        

    )
}