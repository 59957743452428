import * as React from "react";
import HeaderBack from "../utils/HeaderBack";
import Heading from "../utils/typography/Heading";
import Paragraph from "../utils/typography/Paragraph";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/system";
import vagaro_icon from "../assets/img/vagaro.svg";
import gg_icon from "../assets/img/glossgenius.svg";
import schedulicity_icon from "../assets/img/schedulicity.png";
import yelp from "../assets/img/yelp.png";
import booksy from "../assets/img/booksy.png";
import zenoti from "../assets/img/zenoti.png";
import acuity from "../assets/img/acuity.jpeg";
import massageBook from "../assets/img/massageBook.png";
import fresha from "../assets/img/fresha.png";
import ss_icon from "../assets/img/styleseat.svg";
import square_icon from "../assets/img/square.svg";
import others_icon from "../assets/img/others.svg";
import no_booking_icon from "../assets/img/no-booking.svg";
import FixedBottom from "../utils/FixedBottom";
import BookingLink from "./BookingLink";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import base_url, { base_slack_channel } from "../backendUrl";
import { ServicesContext } from "./context/context";
import { useContext } from "react";
import { GlobalContext } from "../context/Global";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import sendSlackNotification from "../utils/sendSlackNotification";
import { useNavigate } from "react-router-dom";
import postOnboardingStage from "../apis/postOnboardingStage";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const commonStyles = {
  m: 1,
  width: "100%",
  maxWidth: "120px",
  height: "90px",
  borderRadius: "12px",
  border: "1px solid #CED0D0",
  padding: "16px",
};

const selectedBoxStyles = {
  border: "1px solid #C94295",
};

const gridStyle = {
  width: "100%",
  marginLeft: "0",
  display: "flex",
  justifyContent: "space-evenly",
};

const circle = {
  background: "linear-gradient(202.4deg, #F5448F -43.81%, #263AA7 182.55%)",
  opacity: "0.1",
  width: "40px",
  height: "40px",
  borderRadius: "100%",
};

const para = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#0C1414",
  fontWeight: "600",
};

export default function Platform(props) {
  const [open, setOpen] = React.useState(false);
  const [platform, setPlatform] = React.useState(null);
  const [nextEnabled, setNextEnabled] = React.useState(false);
  const [bookingLink, setBookingLink] = React.useState(null);
  const [states, setStates] = useContext(ServicesContext);
  const [globals, setGlobals] = useContext(GlobalContext);
  const [bookingPlatformInput, setbookingPlatformInput] = React.useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const noOnlineBooking = async () => {
    setStates({
      action: "change_screen",
      screen_no: 2,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setPlatform(null);
    setStates({ action: "change_screen", screen_no: 0 });
  };

  const onChangebookingPlatformInput = (e) => {
    setbookingPlatformInput(e.target.value);
  };

  const platformClicked = (val) => {
    mixPanelTrackEvent("platform_select_click", {
      type: "Click",
      platform_name: val,
    });
    setPlatform(val);
    if (val === "No booking platform") {
      setNextEnabled(true);
    } else {
      // if booking link not present, disable next button
      if (!bookingLink) {
        setNextEnabled(false);
      }
      setOpen(true);
    }
  };

  const saveBookingPlatform = async (platform) => {
    mixPanelTrackEvent("booking_link_submit", {
      type: "Click",
      booking_link: bookingLink,
    });
    sendSlackNotification(
      base_slack_channel,
      `Booking Platform: ${
        platform?.toString() ? platform?.toString() : "Not found"
      }\nBooking Link: ${bookingLink ? bookingLink : "Skipped"}`
    );

    //save booking link
    const url = `${base_url}/onboard/bookinglink-to-services`;

    const payload = {
      place_id: localStorage.getItem("place_id"),
      uid: localStorage.getItem("uid"),
      booking_platform: platform ? platform?.toString() : null,
    };

    payload["booking_link"] = bookingLink;

    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
      redirect: "follow",
    });
    setOpen(false);
    setPlatform(null);
    if (platform?.toLowerCase() == "no booking platform") {
      navigate("/onboard/client-storage");
    } else {
      window.location.href = `${window.location.origin}/automate-reviews`;
    }
  };

  const handleNext = async (flag = "next") => {
    if (flag !== "skip") setLoading(true);
    await postOnboardingStage(
      "automate-reviews",
      localStorage["uid"],
      localStorage["email"]
    );
    if (flag === "skip") {
      setNextEnabled(false);
      saveBookingPlatform(platform);
    }
    if (!nextEnabled) return;
    if (platform?.toLowerCase() == "other") {
      setNextEnabled(false);
      if (bookingPlatformInput && bookingPlatformInput != "") {
        saveBookingPlatform(bookingPlatformInput);
        setNextEnabled(false);
      } else {
        //useless now because platform is mandatory
        saveBookingPlatform("other");
      }
    } else if (platform?.toLowerCase() == "no booking platform") {
      saveBookingPlatform(platform?.toLowerCase());
      await sendSlackNotification(base_slack_channel, `No Booking Platform`);
      navigate("/onboard/client-storage");
    } else {
      saveBookingPlatform(platform.toLowerCase());
    }

    setLoading(false);
  };

  React.useEffect(() => {
    mixPanelTrackEvent("platform_selection_open", {
      type: "Page Open",
    });
  }, []);

  const platforms = [
    { name: "Styleseat", image: ss_icon },
    { name: "Booksy", image: booksy },
    { name: "Gloss Genius", image: gg_icon },
    { name: "Fresha", image: fresha },
    { name: "MassageBook", image: massageBook },
    { name: "Acuity", image: acuity },
    { name: "Zenoti", image: zenoti },
    // { name: 'Schedulicity', image: schedulicity_icon },
    { name: "Vagaro", image: vagaro_icon },
    { name: "Square", image: square_icon },
    // { name: 'Yelp', image: yelp },
    { name: "Other", image: others_icon },
    { name: "No booking platform", image: no_booking_icon },
  ];

  return (
    <Box style={{ marginBottom: "160px" }}>
      <HeaderBack
        back={() => {
          navigate(-1);
          setStates({ action: "change_screen", screen_no: 1 });
        }}
        style={{ backgroundColor: "#F0F0F0" }}
      />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading style={{ paddingTop: 0 }}>
          Where do you take online bookings?
        </Heading>
        <Paragraph
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
            marginBottom: "30px",
          }}>
          We use it for your google profile completion, website and get you
          leads
        </Paragraph>

        <div
          container
          spacing={2}
          padding={2}
          style={{ display: "flex", flexWrap: "wrap" }}>
          {platforms.map(({ name, image, index }) => (
            <Grid
              xs={6}
              sm={6}
              key={index}
              style={{
                minWidth: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  ...commonStyles,
                  ...(platform == name ? selectedBoxStyles : {}),
                }}
                border={platform == name}
                onClick={() => {
                  platformClicked(name);
                }}>
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={image}
                  alt=""
                />
                <Paragraph mt={2} style={{ ...para }}>
                  {name}
                </Paragraph>
              </Box>
            </Grid>
          ))}
        </div>
      </Container>

      <Dialog
        open={(props.open ?? false) || open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            width: globals.width,
            margin: 0,
            paddingBottom: 4,
            position: "absolute",
            bottom: "97px",
          },
        }}>
        <BookingLink
          bookingPlatformInput={platform}
          bookingLinkInput={bookingLink}
          hc={handleClose}
          selectedOtherBookingPlatform={
            platform?.toLowerCase() == "other" ? true : false
          }
          onChangebookingPlatformInput={onChangebookingPlatformInput}
          onClick={() => {
            mixPanelTrackEvent("booking_link_input_click", {
              type: "Click",
            });
          }}
          setNextEnabled={setNextEnabled}
          setBookingLink={setBookingLink}
          bookingLink={bookingLink}
        />
      </Dialog>

      <FixedBottom
        loader={loading}
        nextEnabled={nextEnabled}
        onClickSkip={() => handleNext("skip")}
        onClickNext={handleNext}
        buttonText="DONE"
        fillPercent={45}
        // 'others' platform option bottomsheet should not have a skip option anymore
        showSkipButton={open && platform?.toLowerCase() !== "other"}
      />
    </Box>
  );
}
