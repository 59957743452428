import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";

const CheckBox = ({
  handleSelectOption,
  selectedOptions,
  options,
  handleBoxClick,
}) => {
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend" />

        <RadioGroup
          name="options"
          value={selectedOptions}
          onChange={(e) =>
            handleSelectOption({ target: { value: e.target.value } })
          }>
          {options.map((item, index) => (
            <div
              key={index}
              onClick={() => handleBoxClick(item.option)}
              className="location-box"
              style={{
                borderRadius: "20px",
                borderWidth: "1.5px",
                borderStyle: "solid",
                borderColor: selectedOptions.includes(item.option)
                  ? "#C14296"
                  : "#CED0D0",
                marginBottom: "16px",
                padding: "0px 30px 0px 10px",
                cursor: "pointer", // Make it clear that it's clickable
              }}>
              <FormControlLabel
                value={item.option}
                control={
                  <Checkbox
                    sx={{
                      color: "black",
                      "&.Mui-checked": { color: "black" },
                    }}
                    checked={selectedOptions.includes(item.option)}
                    onChange={(e) => handleSelectOption(e)}
                  />
                }
                label={item.heading}
                onClick={(e) => {
                  // Prevents the click event from propagating to the parent div
                  e.stopPropagation();
                  handleSelectOption(e);
                }}
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default CheckBox;
