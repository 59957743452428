// import { styled } from '@mui/material/styles';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PrimaryButton from "../utils/Button";
import Highlight from "../utils/Highlight";
import Paragraph from "../utils/typography/Paragraph";
import SubHeading from "../utils/typography/SubHeading";
import Heading from "../utils/typography/Heading";
import HeaderCross from "../utils/HeaderCross";
import Tick from "../assets/img/icons/profileComp.svg";
import Updates from "../assets/img/icons/RegularUpdates.svg";
import Share from "../assets/img/icons/shareProfile.svg";
import { useEffect } from "react";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";

const commonStyles = {
  m: 1,
  width: "40px",
  height: "40px",
  marginLeft: 0,
};

const imgStyles = {
  m: 1,
  width: "28px",
  height: "28px",
  marginLeft: 0,
};

function Access({ redirect_url, stateChanger }) {
  useEffect(() => {
    mixPanelTrackEvent("permissions_priming_open", {
      type: "Page Open",
    });
    sendSlackNotification(
      base_slack_channel,
      "All the Google OAuth Permissions not given"
    );
  }, []);

  return (
    <div
      style={{
        transform: " translate3d(0%, 0px, 0px)",
        width: "100vw",
        overflow: "hidden",
        height: window.innerHeight,
      }}>
      <HeaderCross
        back={() => {
          stateChanger(2);
        }}
      />
      <div
        style={{
          padding: "0px 20px 0px 20px",
        }}>
        <Heading paddingTop={0} paddingBottom={"32px"}>
          Allow Chrone to access your google account permission to help with
        </Heading>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={1.5}>
                  <Box sx={{ ...commonStyles, margin: "12px", marginTop: 0 }}>
                    {" "}
                    <img style={{ ...imgStyles }} src={Tick} />{" "}
                  </Box>
                </Grid>
                <Grid
                  sx={{ alignContent: "center", alignContent: "flex-start" }}
                  container
                  item
                  xs={8.5}>
                  <SubHeading
                    style={{ marginBottom: "4px" }}
                    font_size={16}
                    line_height={24}>
                    {" "}
                    Profile completion{" "}
                  </SubHeading>
                  <Paragraph font_size={12} line_height={16}>
                    Fill and update profile according to what best suits you{" "}
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={1.5}>
                  <Box sx={{ ...commonStyles, margin: "12px", marginTop: 0 }}>
                    {" "}
                    <img style={{ ...imgStyles }} src={Updates} />{" "}
                  </Box>
                </Grid>
                <Grid
                  sx={{ alignContent: "center", alignContent: "flex-start" }}
                  container
                  item
                  xs={8.5}>
                  <SubHeading
                    style={{ marginBottom: "4px" }}
                    font_size={16}
                    line_height={24}>
                    {" "}
                    Regular profile updates{" "}
                  </SubHeading>
                  <Paragraph font_size={12} line_height={16}>
                    Post photos, reviews and posts
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={1.5}>
                  <Box sx={{ ...commonStyles, margin: "12px", marginTop: 0 }}>
                    {" "}
                    <img style={{ ...imgStyles }} src={Share} />{" "}
                  </Box>
                </Grid>
                <Grid
                  sx={{ alignContent: "center", alignContent: "flex-start" }}
                  container
                  item
                  xs={8.5}>
                  <SubHeading
                    style={{ marginBottom: "4px" }}
                    font_size={16}
                    line_height={24}>
                    {" "}
                    Share profile insights{" "}
                  </SubHeading>
                  <Paragraph font_size={12} line_height={16}>
                    Profile visits, clicks, interactions etc.
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Highlight mt={5} style={{ color: "#000000" }}>
          We do not change your account settings without your permission
        </Highlight>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          left: 0,
          bottom: 0,
          width: "100%",
          margin: "20px",
          background: "#fff",
        }}>
        <PrimaryButton
          style={{}}
          disabled={false}
          onClick={() => {
            mixPanelTrackEvent("allow_permission_click", {
              type: "Click",
            });
            sendSlackNotification(
              base_slack_channel,
              "All Google OAuth Permissions not given"
            );
            stateChanger(2);
          }}>
          ALLOW PERMISSION
        </PrimaryButton>
      </div>
    </div>
  );
}

export default Access;
