import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Union from "../assets/img/Union.svg";

const HeaderCross = (props) => {
  return (
    <div
      style={{
        padding: "20px",
        paddingBottom: "14px",
        backgroundColor: props.backColor,
      }}
      className="flex-space-header"
    >
      <span
        style={{ width: "65px", display: "flex", justifyContent: "flex-start" }}
      >
        <CloseIcon
          onClick={props.back}
          style={{
            display: "flex",
            width: "18px",
            height: "18px",
            alignSelf: "center",
            cursor: "pointer",
          }}
        />
      </span>
      <span
        style={{ width: "65px", display: "flex", justifyContent: "center" }}
      >
        <img className="logo-sm" src={Union} alt="logo" />
      </span>
      <p
        style={{ width: "65px", textAlign: "right", cursor: "pointer" }}
        className="help"
        onClick={() => {
          window.location.href = "tel:+12155150601"
        }}
      >
        Help?
      </p>
    </div>
  );
  // return (
  //   <div style={props.style}  className='flex-space-header'>
  //     <span  style={{width:'65px', display:'flex', justifyContent:'flex-start'}}>
  //       <CloseIcon  onClick={props.back}  style={{display: 'flex', alignSelf : 'center'}}  />
  //       </span>
  //       <span style={{width:'65px', display:'flex', justifyContent:'center'}}>
  //       <img className='logo-sm'  src={Union} alt="logo" />
  //       </span>
  //       <p  style={{width:'65px', textAlign:'right'}} className='help'>Help ?</p>
  //   </div>
  // );
};

export default HeaderCross;
