import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

const settingBoxStyle = {
    padding : '12px 16px',
    background: 'rgb(217 119 161 / 12%)',
    borderRadius: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000'
}

const paraStyle = {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Red Hat Display',
}


const Highlight = (props) => {
    return(
    <Box mt={props.mt} sx={{ ...settingBoxStyle, borderRadius: '16px' }} >
        <Typography sx={{...props.style, ...paraStyle}}>
            {props.children}
        </Typography>
    </Box>
    );
}

export default Highlight;