import React from "react";

const PrimaryButton = (props) => {
  const getButtonClass = () => {
    if (props.forceEnabled == true) {
      return "btn-cls";
    } else if (props.forceEnabled == false) {
      return "btn-cls-disabled";
    }

    // adding props.disabled instead of state=='clicked' i maitaine button state according to disable conditions
    if (props.disabled) {
      return "btn-cls-clicked";
    }

    if (props.enabled === undefined || props.enabled) {
      return "btn-cls";
    } else {
      return "btn-cls-disabled";
    }
  };

  return (
    <button
      {...props}
      style={{ cursor: "pointer", ...props.style }}
      onClick={() => {
        if (props.forceEnabled || props.enabled || props.disabled == false) {
          setTimeout(() => {
            props.onClick();
          }, 100);
        }
      }}
      className={getButtonClass()}>
      {props.children}
    </button>
  );
};

export default PrimaryButton;
