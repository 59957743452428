import * as React from 'react';
import Card from '@mui/material/Card';
import SubHeading from '../utils/typography/SubHeading';
import Paragraph from '../utils/typography/Paragraph';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Stack } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { ServicesContext } from './context/context';
import { mixPanelTrackEvent } from '../utils/mixpanel';

export default function ServiceTypeCard({ services, category }) {

    let service = {};
    const [states, setStates] = useContext(ServicesContext);
    service['place_id'] = localStorage.getItem('place_id');
    service['category'] = category;
    service["star"] = false
    service["currency"] = "USD"
    service["pricing_type"] = "STARTS_FROM"

    return (
        <Card sx={{ minWidth: 275, mt: 2, border: '1px solid #CED0D0', borderRadius: '10px', boxShadow: 'none', padding: '10px 0' }}>
            <Stack spacing={0}>
                {services.map((service_i, idx) =>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} paddingX={2} onClick={() => {
                        mixPanelTrackEvent('recommended_service_add_click', {
                            type: 'Click',
                            service_name: service_i
                        })
                        service["name"] = service_i;
                        setStates({ action: 'change_screen_service', screen_no: 5, service: service })
                    }}>
                        <Paragraph style={{ fontSize: '16px'}}> {service_i} </Paragraph>
                        <SubHeading>
                            <div style={{ padding: "5px" }}>
                                <ChevronRightIcon />
                            </div>
                        </SubHeading>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
}