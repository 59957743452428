import google from '../assets/img/google.svg';
import gmail from '../assets/img/gmail.svg';
import GoogleButton from '../utils/GoogleButton';
import Badge from '@mui/material/Badge';
import Heading from '../utils/typography/Heading';
import Paragraph from '../utils/typography/Paragraph';
import HeaderEmpty from '../utils/HeaderEmpty';
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from '../context/Global';
import { mixPanelTrackEvent } from '../utils/mixpanel';
import CircularProgress from "@mui/material/CircularProgress";
import sendSlackNotification from '../utils/sendSlackNotification';
import { base_slack_channel } from '../backendUrl';

// import SubHeading from '../utils/typography/SubHeading';


function Connect(props) {
  const [globals] = useContext(GlobalContext);
  const [loader, setLoader] = useState(false);

  if(localStorage["landing"]==undefined){
    localStorage["landing"] = true;
  }

  useEffect(() => {
    if(localStorage["splash"]=='true'){
      localStorage["splash"] = false;
      mixPanelTrackEvent('connect_google_screen_open', {
        type: 'Page Open',
        srctag: localStorage.getItem("srcTag")
      })
    }

    sendSlackNotification("temp-channel", `Connect.js localStorage.getItem('srcTag'): ${localStorage.getItem('srcTag')}`)
  }, [])

  return (
    <div
      style={{
        height: globals.height,
        display: "flex",
        flexDirection: "column-reverse",
        right: globals.width
      }}
    >
      <div className='side-padding-e' style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "20px 20px"
      }}>
        <div>
          <Heading className={`${globals.browser=='Apple Safari'?" apple-bold":""}`} style={{padding: "12px 0 8px 0"}}>Connect the Google account linked with your business</Heading>
          {localStorage.getItem('email') && localStorage.getItem('email')!='' && false ? <Paragraph style={{ fontSize: '16px', lineHeight: '24px', marginTop: '20px' }}>
            {localStorage.getItem('biz_name')} is currently linked with an email starting with <b style={{ color: 'black', fontFamily: "Red hat display bold" }}>{localStorage.getItem('email')}</b>
          </Paragraph> : <Paragraph style={{ fontSize: '16px', lineHeight: '24px', marginTop: '20px' }}>
          Choose the correct email which is linked with your Google business profile
          </Paragraph>}
        </div>
        <div style={{width: '100%', height: '20vh'}}></div>
        <div className='full-button'>
          <GoogleButton onClick={async() => {
            mixPanelTrackEvent('continue_w_google_click', {
              type: "Click"
            });
            setLoader(true);
            await sendSlackNotification(base_slack_channel, 'Google Signin Clicked');
            setLoader(false);
            window.location.href = props.redirect_url;
          }}>
            <img src={google} alt='google' /> 
            CONTINUE WITH GOOGLE 
            {loader ? <CircularProgress size={20} style={{ marginLeft: '10px', color:'white' }} /> : null }
          </GoogleButton>
        </div>
        {/* <div className='full-button'>
          <div style={{
            background:"grey",
            padding:"0.8rem 0",
            borderRadius:"40px",
            width:"100%",
            textAlign:'center'
          }}>
            <SubHeading style={{color:"#fff"}}>I DON'T HAVE GBP ACCOUNT</SubHeading>
          </div>
        </div> */}
      </div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1
      }}>
        <HeaderEmpty style={{ background: '#F0F0F0', padding: '20px', paddingBottom: "14px" }} />
        <div className="section-e" >
          {
            localStorage.getItem('email') && localStorage.getItem('email')!='' && false ? <Badge className="tooltip-badge" badgeContent={localStorage.getItem('email')} max={999}>
              <img className='gmail' src={gmail} alt="gmail" />
            </Badge> :
            <img className='gmail' src={gmail} alt="gmail" />
          }
        </div>
      </div>
    </div>
  );
}

export default Connect;
