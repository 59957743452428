import axios from "axios";
import base_url from "../backendUrl";
import sendSlackNotification from "../utils/sendSlackNotification";

export const reviewAutomationPreferences = async (key, value, uid) => {
  let data = JSON.stringify({
    key: key,
    value: value,
    uid: uid,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${base_url}/review-automation-preference`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    // console.log(config);
    const resp = await axios.request(config);

    await sendSlackNotification(
      "temp-channel",
      `posted value for ${key}: ${value}\n ${uid}`
    );
  } catch (e) {
    await sendSlackNotification(
      "temp-channel",
      `Error: Couldn't post value ${key}: ${value}\n ${uid} \n ${e}`
    );
  }
};
