import React, { useState, createContext } from "react";

// Create Context Object
export const CounterContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const CounterContextProvider = props => {
  const initState = {
    count: 0,
    services: []
  }
  const [state, setState] = useState(initState);

  const dispatch = (payload) => {
    let new_services, old_services;

    switch(payload.action) {
      case "update_counter":
        setState({
          ...state,
          count: state.count + payload.data
        })
        break;
      case "add_service":
        new_services = state.services;
        new_services.push({
          "serviceName": "New Service",
          "price": 100
        })

        new_services = new_services.map((e, i) => {
          return {
            ...e,
            index: Date().toString()
          }
        })
        setState({
          ...state,
          services: new_services
        })
        break;
      case "remove_service":
        old_services = state.services;
        if(old_services.length>0){
          new_services = old_services.slice(0, old_services.length - 1);
        } else{
          new_services = old_services;
        }

        new_services = new_services.map((e, i) => {
          return {
            ...e,
            index: Date().toString()
          }
        })
        setState({
          ...state,
          services: new_services
        })
        break;
      default:
        setState(state);
    }
  }

  return (
    <CounterContext.Provider value={[state, dispatch]}>
      {props.children}
    </CounterContext.Provider>
  );
};