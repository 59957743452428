import HeaderCross from "../utils/HeaderCross";
import Heading from "../utils/typography/Heading";
import { Container } from "@mui/material";
import ServiceEditForm from "./ServiceEditForm";
import PrimaryButton from "../utils/Button";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Paragraph from "../utils/typography/Paragraph";
import Stack from '@mui/material/Stack';
import { useContext } from "react";
import { GlobalContext } from '../context/Global';

export default function ServiceEdit({ service, updateService, cancelEdit, deleteService }) {
    const [globals] = useContext(GlobalContext);
    const del = () => {
        deleteService(service);
    }
    return (
        <div style={{
            width: globals.width,
            height: globals.height,
            position: 'fixed',
            top: 0,
            left: (window.innerWidth-globals.width)/2
        }}>
            <HeaderCross back={cancelEdit} style={{ marginTop: 10 }}/>
            <Container>
                <Stack direction="row" justifyContent="space-between" alignItems="baseline">
                    <Heading>Edit service details</Heading>
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={del}>
                        <DeleteOutlineIcon sx={{ color: '#BD463E' }} />
                        <Paragraph style={{ color: '#BD463E' }}>Delete</Paragraph>
                    </div>
                </Stack>
                <ServiceEditForm text={'SAVE CHANGES'} service={service} updateService={updateService} /> 
            </Container>
        </div>
    )
}