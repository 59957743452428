import React, { useState, useContext, useEffect } from "react";
import { TextField, Button, Autocomplete } from "@mui/material";
import { GlobalContext } from "../context/Global";
import base_url, { base_slack_channel } from "../backendUrl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Box from "@mui/material/Box";
import Heading from "../utils/typography/Heading";
import RefreshIcon from "@mui/icons-material/Refresh";
import SubHeading from "../utils/typography/SubHeading";
import call from "../assets/img/icons/call.svg";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import PrimaryButton from "../utils/Button";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import sendSlackNotification from "../utils/sendSlackNotification";

const VerifyOTP = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [globals] = useContext(GlobalContext);
  const [otp, setOtp] = useState("");
  const [mode, setMode] = useState("text");
  const [openalert, setOpenAlert] = useState(false);
  const [otpsuccess, setOtpsuccess] = useState(false);
  const [otpresend, setOtpresend] = useState(false);
  const [otpcall, setOtpcall] = useState(false);
  const [loader, setLoader] = useState(false);
  const countrycode = urlParams.get("countrycode");
  const contactNumber = urlParams.get("contact");
  const business_location = localStorage.getItem("business_location");
  const business_email = localStorage.getItem("email");

  const navigate = useNavigate();

  const resendOTP = () => {
    setOtpresend(true);
    sendSlackNotification(base_slack_channel, `Resend OTP clicked`);
    setTimeout(() => {
      setOtpresend(false);
    }, 3000);
    const data = {
      is_retry: true,
      type: "sms",
      country_code_num: countrycode,
      business_phone: contactNumber,
      business_location: business_location,
      business_email: business_email,
      is_test: false,
    };
    const response = fetch(`${base_url}/chrone_webapp/create-gbp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        localStorage.setItem("verification_token", data.verification_token);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const loginWithoutOTP = async () => {
    setLoader(true);
    await sendSlackNotification(
      base_slack_channel,
      `Create GBP OTP skip clicked`
    );

    const location = localStorage["location"];
    if (location != undefined) {
      const item = localStorage["srcTag"];

      let payload = {
        email: localStorage["email"],
        place_id: localStorage["place_id"],
        location_name: localStorage["location"],
        pd_id: localStorage["pd_id"] ?? "",
        gbp_created: true,
      };

      if (
        window.location.href.includes("web.chrone") ||
        localStorage["test_source"] === "web.chrone.io"
      ) {
        payload["src"] = "Web";
      } else {
        payload["src"] = "App";
      }
      const url = `${base_url}/gmb_save_biz_data_react`;
      const response1 = await fetch(url, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const resp = await response1.json();
      localStorage.setItem("uid", resp["res"]["uid"]);
    }

    setOtpsuccess(false);
    window.location.href = localStorage["uid"]
      ? `${window.location.origin}/onboard/priming?uid=${localStorage["uid"]}`
      : `${window.location.origin}/onboard/priming`;

    mixPanelTrackEvent("location_select_success_click", {
      type: "Click",
      location,
    });
  };

  const submitOtp = async () => {
    setLoader(true);
    if (otp.length === 6) {
      await sendSlackNotification(
        base_slack_channel,
        `Create GBP OTP entered: ${otp}`
      );
      var data = {
        otp: otp,
        verification_token: localStorage.getItem("verification_token"),
        is_test: false,
        business_email: localStorage.getItem("email"),
      };
      const location = localStorage["location"];
      if (location != undefined) {
        const item = localStorage["srcTag"];

        let payload = {
          email: localStorage["email"],
          place_id: localStorage["place_id"],
          location_name: localStorage["location"],
          pd_id: localStorage["pd_id"] ?? "",
          gbp_created: true,
        };

        if (
          window.location.href.includes("web.chrone") ||
          localStorage["test_source"] === "web.chrone.io"
        ) {
          payload["src"] = "Web";
        } else {
          payload["src"] = "App";
        }
        const url = `${base_url}/gmb_save_biz_data_react`;
        const response1 = await fetch(url, {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        });
        const resp = await response1.json();
        localStorage.setItem("uid", resp["res"]["uid"]);
      }
      const response = fetch(`${base_url}/chrone_webapp/verify-gbp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          setOtpsuccess(true);
          setTimeout(() => {
            setOtpsuccess(false);
            window.location.href = `${window.location.origin}/onboard/priming`;
          }, 3000);
        })
        .catch((error) => {
          setOpenAlert(true);
          setTimeout(() => {
            setOpenAlert(false);
          }, 3000);
          setOtp("");
          window.location.reload(true);
          console.error("Error:", error);
        });
      mixPanelTrackEvent("location_select_success_click", {
        type: "Click",
        location,
      });
    }
  };

  const sendcall = () => {
    setMode("call");
    setOtpcall(true);
    sendSlackNotification(base_slack_channel, `OTP via Call clicked`);
    setTimeout(() => {
      setOtpcall(false);
    }, 3000);
    const data = {
      is_retry: true,
      type: "phone_call",
      country_code_num: countrycode,
      business_phone: contactNumber,
      business_location: business_location,
      business_email: business_email,
      is_test: false,
    };
    const response = fetch(`${base_url}/chrone_webapp/create-gbp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      document.getElementById("verification-skip-btn").style.display = "flex";
    }, 20000);
  }, []);

  return (
    <div
      style={{
        height: globals.height,
        width: globals.width,
        backgroundSize: "contain",
        margin: "auto",
        padding: "0 20px",
      }}>
      <ArrowBackIosIcon
        onClick={() => {
          navigate(-1);
        }}
        style={{ margin: "20px 0" }}
      />
      <Heading>
        Verify your profile. Enter the OTP sent to +{countrycode}-
        {contactNumber} via {mode}
      </Heading>

      <Box
        sx={{
          border: "1px solid #B0B0B0",
          borderRadius: "13px",
          display: "flex", // Here we are using Flexbox
          alignItems: "center", // Align items in the center
          p: 1, // Padding
          height: "43px",
          margin: "2rem 0 0 0",
        }}>
        <TextField
          sx={{
            letterSpacing: "0.3em",
            flex: "0.80",
            alignContent: "center",
            paddingBottom: "10px",
            width: "100%", // Make sure it takes up full width
            "& .MuiInputBase-input": {
              // targeting the input field specifically
              margin: "0 0.8rem",
              letterSpacing: "0.5rem",
            },
          }}
          onInput={(e) => {
            const inputValue = e.target.value;
            const sanitizedValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters
            const formattedValue = sanitizedValue.slice(0, 6);
            setOtp(formattedValue);
          }}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{
            sx: { color: "grey" }, // This changes the color of the label
          }}
          id="standard-basic"
          label="OTP"
          variant="standard"
          type="number"
          value={otp}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "2rem 0",
        }}>
        <PrimaryButton
          style={{ cursor: "pointer" }}
          onClick={submitOtp}
          disabled={false}>
          <>
            VERIFY OTP
            {loader ? (
              <CircularProgress
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "10px",
                  color: "#000000",
                }}
              />
            ) : null}
          </>
        </PrimaryButton>
      </Box>
      <div
        id="verification-skip-btn"
        onClick={() => {
          loginWithoutOTP();
        }}
        style={{
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          cursor: "pointer",
        }}>
        <SubHeading
          style={{
            textDecoration: "underline",
          }}>
          Skip Verification
        </SubHeading>
      </div>
      {openalert && <Alert severity="error">OTP is wrong please retry</Alert>}
      {otpsuccess && (
        <Alert severity="success">OTP verified successfully</Alert>
      )}
      {otpresend && <Alert severity="info">OTP resend successfully</Alert>}
      {otpcall && (
        <Alert severity="info">Calling you for the OTP please pick up</Alert>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "1rem",
          position: "absolute",
          bottom: 20,
          width: globals.width * 0.9,
        }}>
        <div
          onClick={resendOTP}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
            cursor: "pointer",
          }}>
          <RefreshIcon />
          <SubHeading
            style={{
              textDecoration: "underline",
            }}>
            Resend OTP
          </SubHeading>
        </div>
        <div
          onClick={sendcall}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 4,
            cursor: "pointer",
          }}>
          <img src={call} />
          <SubHeading
            style={{
              textDecoration: "underline",
            }}>
            Send OTP on call
          </SubHeading>
        </div>
      </Box>
    </div>
  );
};

export default VerifyOTP;
