import React, { useEffect } from 'react';


const MobileApp = () => {
  useEffect(() => {
    if(navigator?.platform=='iPhone') {
      window.location.href =  "https://apps.apple.com/app/chrone/id1610101236";
    } else if(navigator.userAgentData.platform=="Android") {
      window.location.href =  "https://play.google.com/store/apps/details?id=com.timelyai.timelyai";
    } else{
      window.location.href =  "https://apps.apple.com/app/chrone/id1610101236";
    }
  }, [])

  return (
    <div style={{
      backgroundSize: "contain",
      margin: "auto",
      padding: "0 20px"
    }}>
    </div>
  )
}

export default MobileApp;