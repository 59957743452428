import React from "react";
import Union from "../assets/img/Union.svg";

const HeaderEmpty = (props) => {
  return (
    <div style={props.style} className="flex-space-header">
      <span style={{ width: "65px" }}></span>
      <span
        style={{ width: "65px", display: "flex", justifyContent: "center" }}
      >
        <img className="logo-sm" src={Union} alt="logo" />
      </span>
      <p
        style={{ width: "65px", textAlign: "right", cursor: "pointer" }}
        className="help"
        onClick={() => {
          window.location.href = "tel:+12155150601"
        }}
      >
        Help?
      </p>
    </div>
  );
};

export default HeaderEmpty;
