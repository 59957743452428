import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';


const BookingLinkTextField = styled((props) => (
    <TextField InputProps={{ disableUnderline: true }} {...props} />
  ))(({ theme }) => ({
    '& .MuiFilledInput-root': {
      border: '1px solid #CED0D0',
      overflow: 'hidden',
      borderRadius: 12,
      backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
      transition: theme.transitions.create([
        'border-color',
        'background-color',
        'box-shadow',
      ]),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: 'transparent',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        // borderColor: theme.palette.primary.main,
      },
    },
    '& .Mui-disabled' : {
      backgroundColor : 'rgba(240, 240, 240, 0.5)'
    },
    '& .MuiInputLabel-root' : {
      '&.Mui-focused': {
        color : 'inherit'
      }
    },
  }));

export default function InputField(props){
    return(
        <BookingLinkTextField
                label={props.label}
                defaultValue={props.defaultValue}
                name = {props.name}
                id={props.id}
                onChange = {props.onChange}
                {...props}
                variant="filled"
                style={{
                  marginTop: 16,
                  width: props.width ?? '100%',
                  marginRight: props.marginRight ?? undefined
                }}
        />
    )
}
  

