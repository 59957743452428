import Home from "./Home";
import Connect from "./Connect";
import Access from "./Access";
import base_url from "../backendUrl";
import { useState, useEffect, useContext } from "react";
import Splash from "./Splash";
import { useTransition, animated } from "react-spring";
import { GlobalContext } from "../context/Global";
import { mixPanelIdentifyUser } from "../utils/mixpanel";
import SelectLocation from "./SelectLocation";
import sendSlackNotification from "../utils/sendSlackNotification";
import DevUrlHandling from "../utils/DevUrlHandling";

function Onboard() {
  let urlParams = new URLSearchParams(window.location.search);
  let status_code = parseInt(urlParams.get("status") || 0);
  const pd_id = urlParams.get("pd_id") || null;

  const [state, setState] = useState(status_code);
  const [biz, setBiz] = useState("");
  const [email, setEmail] = useState("");
  const [redirect_url, set_redirect_url] = useState("/");
  const [globals] = useContext(GlobalContext);

  const splashTransitions = useTransition(state, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 0,
    },
  });

  const homeTransitions = useTransition(state, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, transform: "translate3d(-100%,0,0)" },
    config: {
      duration: 200,
    },
  });

  const pageTransitions = useTransition(state, {
    from: { opacity: 1, transform: `translate3d(100%,0,0)` },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-100%,0,0)" },
    config: {
      duration: 200,
    },
  });

  const fetchGoogleOauthLink = async () => {
    let url = base_url + "/login-react?place_id=" + localStorage.getItem("place_id") + "&origin=app";
    if (window.location.href.includes('web.chrone') || localStorage["test_source"] === 'web.chrone.io')
      url = base_url + "/login-react?place_id=" + localStorage.getItem("place_id") + "&origin=web";



    try {
      const response = await fetch(url);
      const json = await response.json();
      console.log(json.url);
      localStorage["redirect_url"] = json.url;
      set_redirect_url(json.url);
    } catch (error) {
      console.log("error", error);
    }
  };

  const check_user = async () => {
    const url = `${base_url}/check_user?place_id=${localStorage.getItem(
      "place_id"
    )}`;

    try {
      const response = await fetch(url);
      const json = await response.json();
      console.log(json.res);
      if (json.res == "user") {
        const item = localStorage["srcTag"];

        await sendSlackNotification(
          "temp-channel",
          `App.js localStorage["srcTag"] - ${localStorage["srcTag"]}`
        );

        if (window.location.href.includes('web.chrone') || localStorage["test_source"] === 'web.chrone.io')
          window.location.href = `${base_url}/old-dashboard-transit?place_id=${localStorage["place_id"]}&uid=${localStorage["uid"]}&location=${localStorage["location"]}`;
        else {
          window.location.href = `chrone://close-browser?uid=${localStorage["uid"]}`;
        }
      }
    } catch (e) { }
  };

  useEffect(() => {
    urlParams = new URLSearchParams(window.location.search);
    status_code = parseInt(urlParams.get("status") || 0);
    DevUrlHandling()
    const place_id =
      urlParams.get("place_id") ?? localStorage.getItem("place_id") ?? "";
    localStorage.setItem("place_id", place_id);

    const pd_id =
      urlParams.get("pd_id") ?? localStorage.getItem("pd_id") ?? null;
    localStorage.setItem("pd_id", pd_id);

    const pre_uid = urlParams.get("pre_uid") ?? null;
    if (pre_uid != null) {
      localStorage.setItem("pre_uid", pre_uid);
    }

    const src_tag = urlParams.get("src_tag") ?? null;
    if (src_tag != null) {
      localStorage.setItem("srcTag", src_tag);
    }

    sendSlackNotification(
      "temp-channel",
      `App.js localStorage.getItem('srcTag'): ${localStorage.getItem("srcTag")}`
    );

    mixPanelIdentifyUser(place_id);

    check_user();

    if (status_code == 2) {
      setState(2);
    } else if (status_code == 3) {
      setState(3);
    } else if (status_code === 0) {
      setState(0);
    }

    fetchGoogleOauthLink();
    // fetchData();
  }, []);

  const pages = {
    0: (<Splash state={state} stateChanger={setState} />),
    1: (window.location.href.includes('web.chrone') ?
      < Home
        redirect_url={redirect_url}
        biz_name={biz}
        mail={email}
        stateChanger={setState}
      /> : <Connect redirect_url={redirect_url} stateChanger={setState} />
    ),
    2: <Connect redirect_url={redirect_url} stateChanger={setState} />,
    3: <Access redirect_url={redirect_url} stateChanger={setState} />,
  };

  return (
    <div>
      {state == 0 &&
        splashTransitions((props, idx, key) => {
          return (
            <animated.div
              key={key}
              style={{
                ...props,
                width: globals.width,
                height: globals.height,
                position: "fixed",
                top: 0,
                left: (window.innerWidth - globals.width) / 2,
              }}
            >
              {pages[idx]}
            </animated.div>
          );
        })}
      {state == 1 &&
        homeTransitions((props, idx, key) => {
          return (
            <animated.div
              key={key}
              style={{
                ...props,
                width: globals.width,
                height: globals.height,
                position: "fixed",
                top: 0,
                left: (window.innerWidth - globals.width) / 2,
              }}
            >
              {pages[idx]}
            </animated.div>
          );
        })}
      {state > 1 &&
        pageTransitions((props, idx, key) => {
          return (
            <animated.div
              key={key}
              style={{
                ...props,
                width: globals.width,
                height: globals.height,
                position: "fixed",
                top: 0,
                left: (window.innerWidth - globals.width) / 2,
              }}
            >
              {pages[idx]}
            </animated.div>
          );
        })}
    </div>
  );
}

export default Onboard;
