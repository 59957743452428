import Onboard from "../onboard/App";
import Access from "../onboard/Access";
import WrongAccount from "../onboard/Wrong";
import ConnectSocial from "../onboard/ConnectSocial";
import Platform from "../dashboard/Platform";
import ServiceEdit from "../dashboard/ServiceEdit";
import SearchService from "../dashboard/SearchService";
import Services from "../dashboard/App";
import ContextApp from "../context/App";
import Index from "../dashboard/Index";
import Connect from "../onboard/Connect";
import Splash from "../onboard/Splash";
import ConnectSquare from "../onboard/ConnectSquare";
import PrimingFinish from "../onboard/PrimingFinish";
import { Route, Routes, useLocation, IndexRoute } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Home from "../onboard/Home";
import Clear from "../onboard/Clear";
import SelectLocation from "../onboard/SelectLocation";
import SetSelectLocation from "../onboard/SetSelectLocation";
import SetPhoneNumber from "../onboard/SetPhoneNumber";
import CreateGBP from "../onboard/CreateGBP";
import Nobiz from "../onboard/Nobiz";
import VerifyOTP from "../onboard/VerifyOTP";
import MobileApp from "../onboard/MobileApp";
import AutomateReviews from "../onboard/AutomateReviews";
import ClientCount from "../onboard/ClientCount";
import ReviewsPledge from "../onboard/ReviewsPledge";
import ReviewAutomationConsent from "../onboard/ReviewAutomation";
import Clientstore from "../onboard/Clientstore";
import Workphotos from "../onboard/Workphotos";
import InstagramConnect from "../onboard/InstagramConnect";

export default function AnimatedRoutes(props) {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Onboard />} />
        <Route path="/onboard" element={<Onboard />} />
        <Route path="/onboard/access" element={<Access />} />
        <Route path="/onboard/no-location" element={<WrongAccount />} />
        <Route path="/onboard/select-locations" element={<SelectLocation />} />
        <Route path="/onboard/priming" element={<ConnectSocial />} />
        <Route path="/services" element={<Index />} />
        <Route path="/onboard/client-storage" element={<Clientstore />} />
        <Route path="/onboard/photos-storage" element={<Workphotos />} />
        <Route
          path="/onboard/connect-instagram"
          element={<InstagramConnect />}
        />
        <Route path="/dashboard/platform" element={<Platform />} />
        <Route path="/services/edit" element={<ServiceEdit />} />
        <Route path="/service/search" element={<SearchService />} />
        <Route path="/context-example" element={<ContextApp />} />
        <Route path="/clear" element={<Clear />} />
        <Route path="/SetLocation" element={<SetSelectLocation />} />
        <Route path="/automate-reviews" element={<AutomateReviews />} />
        <Route path="/review-consent" element={<ReviewAutomationConsent />} />
        <Route path="/reviews-pledge" element={<ReviewsPledge />} />
        <Route path="/setNumber" element={<SetPhoneNumber />} />
        <Route path="/onboard/create-gbp" element={<CreateGBP />} />
        <Route path="/onboard/nobiz" element={<Nobiz />} />
        <Route path="/onboard/verify-otp" element={<VerifyOTP />} />
        <Route path="/client-count" element={<ClientCount />} />
        <Route path="/app" element={<MobileApp />} />
        <Route path="/test-route" element={<div>hey there!!</div>} />
      </Routes>
    </AnimatePresence>
  );
}
