import { useContext, useEffect } from 'react';
import { GlobalContext } from '../context/Global';
import splash from '../assets/img/splash.gif';
import { mixPanelTrackEvent } from "../utils/mixpanel";


function Splash(props) {
  const [globals] = useContext(GlobalContext);
  if(localStorage["splash"]==undefined){
    localStorage["splash"] = true;
  }

  useEffect(() => {
    setTimeout(() => {
      if(props.state==0){
        props.stateChanger(1);
      }
    }, 3900)

    if(localStorage["splash"]=='true'){
      localStorage["splash"] = false;
      mixPanelTrackEvent('splash_screen_open', {
        type: 'Page Open'
      })
    }
  }, [])
  
  return (
    <div style={{
      height: globals.height,
      width: globals.width,
      margin: "auto"
    }}>
      <img width={"100%"} height={"100%"} src={splash} style={{objectFit: "cover"}}></img>
    </div>
  );
}

export default Splash;
