import HeaderBack from "../utils/HeaderBack";
import Heading from "../utils/typography/Heading";
import { Container } from "@mui/system";
import FixedBottom from "../utils/FixedBottom";
import SearchBar from "../utils/SearchBar";
import ServiceTypeCard from "./ServiceTypeCard";
import SubHeading from "../utils/typography/SubHeading";
import { useEffect, useState, useContext } from "react";
import base_url from "../backendUrl";
import SimpleSnackbar from "../utils/SnackBar";
import { ServicesContext } from "./context/context";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import Paragraph from "../utils/typography/Paragraph";
import { Card } from "@mui/material";

function SearchService({ nextEnabled, onClickNext }) {
  const [states, setStates] = useContext(ServicesContext);
  const [categories, setCategories] = useState([]);
  const [rows, setRows] = useState([]);
  const [Catservices, setCatServices] = useState({});

  const url = base_url + "/get_ss_category_services";

  const fetchCategories = async () => {
    try {
      const response = await fetch(url);
      const json = await response.json();
      let result = json.res;
      let CatServ = {};
      let cat = [];

      for (let i = 0; i < result.length; i++) {
        cat.push(result[i]["category"]);
      }

      console.log(CatServ);
      cat = cat.filter((value, index, array) => array.indexOf(value) === index);

      for (let i = 0; i < cat.length; i++) {
        CatServ[cat[i]] = [];
      }

      for (let i = 0; i < result.length; i++) {
        CatServ[result[i]["category"]].push(result[i]["service"]);
      }

      setCatServices(CatServ);
      setRows(cat);
      setCategories(cat);
      setStates({
        action: "set_categories_services",
        categories_services: CatServ,
        categories: cat,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    mixPanelTrackEvent("search_service_screen_open", {
      type: "Page Open",
    });
    fetchCategories();
  }, []);

  const requestSearch = (searchedVal) => {
    let ss = searchedVal.target.value;
    const filteredRows = categories.filter((row) => {
      let x = Catservices[row];
      for (let j = 0; j < x.length; j++) {
        // if(x[j].toLowerCase().includes(ss.toLowerCase())) return true;
        if (x[j].toLowerCase().startsWith(ss.toLowerCase())) return true;
      }

      return false;
    });
    console.log(filteredRows);
    setRows(filteredRows);
  };

  const categories_blocks = rows.map((cat, idx) => (
    <div key={idx}>
      <SubHeading mt={2}>{cat}</SubHeading>

      <ServiceTypeCard services={Catservices[cat]} category={cat} />
    </div>
  ));
  return (
    <div style={{ paddingBottom: "160px", width: "100%" }}>
      <HeaderBack
        back={() => setStates({ action: "change_screen", screen_no: 2 })}
        style={{}}
      />
      <Container>
        <SearchBar
          style={{ width: "100%" }}
          onChange={(searchVal) => requestSearch(searchVal)}
        />
        {categories_blocks}
        <Card
          onClick={() => {
            setStates({
              action: "change_screen_service",
              screen_no: 4,
              service: {},
            });
          }}
          sx={{
            minWidth: 275,
            mt: 2,
            border: "1px solid #CED0D0",
            borderRadius: "10px",
            boxShadow: "none",
            padding: "10px 10px",
          }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <div
              style={{
                borderRadius: "50%",
                backgroundColor: "#F3F3F3",
                width: "48px",
                height: "48px",
              }}></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}>
              <SubHeading>Didn't find the service looking for?</SubHeading>
              <Paragraph style={{ color: "#A1409A" }}>
                Add your own service
              </Paragraph>
            </div>
          </div>
        </Card>
      </Container>

      <SimpleSnackbar show={states.showSnackbar} setSnackbar={setStates} />
    </div>
  );
}

export default SearchService;
