import React from 'react';


const GoogleButton = (props) => {
  return (
    <button {...props} className="btn-cls-google" >
      {props.children}
    </button>
  );
};

export default GoogleButton;
