import React from 'react';
import { GlobalContext } from '../context/Global';
import Paragraph from '../utils/typography/Paragraph';
import PrimaryButton from '../utils/Button';
import { Container } from "@mui/system";
import { useContext, useState } from "react";
import Heading from '../utils/typography/Heading';
import HeaderCross from '../utils/HeaderCross';
import Grid from "@mui/material/Grid";
import sendSlackNotification from '../utils/sendSlackNotification';
import { base_slack_channel } from '../backendUrl';


const Nobiz = () => {
  const [globals] = useContext(GlobalContext);
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email")
  return (
    <div style={{
      height: globals.height,
      width: globals.width,
      backgroundSize: "contain",
      margin: "auto",
      padding: "0 20px"
    }}>
      <HeaderCross />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Heading>No Business Location found</Heading>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: "0",
          width: globals.width*(0.9),
        }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Grid
            sx={{ alignItems: "center", display: "flex", cursor: "pointer" }}
            item
            xs={6}
          >
            <div
              onClick={async () => {
                const oldSrcTag = localStorage.getItem('srcTag');
                localStorage.clear();
                if(oldSrcTag!=null){
                  localStorage.setItem('srcTag', oldSrcTag);
                }
                await sendSlackNotification(base_slack_channel, "No location page - Try another email clicked");
                window.location.href = '/onboard'
              }}
            >
              <Paragraph
                style={{
                  color: "#000000",
                  fontWeight: "800",
                  fontSize: "15px",
                  cursor:"pointer",
                  padding:"1rem"
                }}
              >
                Try another email
              </Paragraph>
            </div>
          </Grid>
          <Grid
            sx={{ alignContent: "center", cursor: "pointer" }}
            container
            item
            xs={6}
          >
            <PrimaryButton
              disabled={false}
              style={{ fontSize: "14px" }}
              onClick={async () => {
                await sendSlackNotification(base_slack_channel, "No location page - Create GBP clicked");
                window.location.href = `/onboard/create-gbp?email=${email}`
              }}
            >
              Create GBP
            </PrimaryButton>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default Nobiz;