import PrimaryButton from "../utils/Button";
import ProgressBar from "../utils/Progress";
import Heading from "../utils/typography/Heading";
import SubHeading from "../utils/typography/SubHeading";
import Paragraph from "../utils/typography/Paragraph";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import base_url, { base_slack_channel } from "../backendUrl";
import React, { useState } from "react";
import reviewLink from "../assets/img/icons/reviewLink.svg";
import star from "../assets/img/icons/SendFollowUpMess.svg";
import { GlobalContext } from "../context/Global";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import sendSlackNotification from "../utils/sendSlackNotification";
import postOnboardingStage from "../apis/postOnboardingStage";
import { CircularProgress } from "@mui/material";
import Banner from "./Banner";
import FixedBottom from "../utils/FixedBottom";

const commonStyles = {
  width: "40px",
  height: "40px",
  alignContent: "flex-start",
};

function InstagramConnect({ stateChanger, new_state }) {
  const [globals] = React.useContext(GlobalContext);
  const [loading, setLoading] = React.useState(false);
  const fixedBottom = {
    position: "fixed",
    // left: (window.innerWidth - globals.width) / 2,
    bottom: 10,
    width: globals.width,
    backgroundColor: "#ffffff",
    color: "white",
    // textAlign: 'center',
    // zIndex: '9999'
  };
  const onClickNext = async () => {
    mixPanelTrackEvent("connect_instagram_click", {
      type: "Click",
    });
    await sendSlackNotification(
      base_slack_channel,
      `Connect Instagram Clicked`
    );
    instaOauth();
  };
  const onHandleSkip = async () => {
    mixPanelTrackEvent("no_instagram_connect_click", {
      type: "Click",
    });
    // stateChanger(3);
    await postOnboardingStage(
      "/setNumber",
      localStorage["uid"],
      localStorage["email"]
    );
    await sendSlackNotification(
      base_slack_channel,
      `Instagram connect Skip Clicked`
    );
    window.location.href = localStorage["uid"]
      ? `/setNumber?uid=${localStorage["uid"]}`
      : "/setNumber";
  };

  const instaOauth = async () => {
    const server_name = "https://timely.work";
    let redirect_url = `${server_name}/instagram/redirect-url/in-app-browser-onboarding`;
    if (window.location.href.includes("web.chrone")) {
      redirect_url = `${server_name}/instagram/redirect-url/web-onboarding`;
    }
    const get_auth_url = `https://api.instagram.com/oauth/authorize?client_id=3738853343009664&redirect_uri=${redirect_url}&scope=user_profile,user_media&response_type=code`;
    window.location.href = get_auth_url;
  };

  React.useEffect(() => {
    mixPanelTrackEvent("connect_instagram_page_open", {
      type: "Page Open",
    });

    // Get uid from URL parameters and store it in localStorage if present
    const params = new URLSearchParams(window.location.search);
    const uid = params.get("uid");
    if (uid) {
      localStorage.setItem("uid", uid);
    }
  }, []);
  return (
    <div style={{ paddingBottom: "120px" }}>
      <div>
        <Banner showInstaVariant={true} backColor={"rgb(240, 240, 240)"} />
      </div>
      <Container
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          // height: `${window.innerHeight * 0.8}px`,
          overflowX: "scroll",
        }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"24px"}
          style={{ paddingLeft: "4px", paddingRight: "4px" }}>
          Connect Instagram to keep your GBP photos updated
        </Heading>
        <Paragraph
          style={{ fontSize: "16px", lineHeight: "24px", marginLeft: "20px" }}>
          With this connection Chrone will:
        </Paragraph>
        <Box mt={4} sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={2} style={{ alignContent: "flex-start" }}>
                  <Box sx={{ ...commonStyles }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none">
                      <mask
                        id="mask0_588_2827"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="36"
                        height="36">
                        <rect width="36" height="36" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_588_2827)">
                        <path
                          d="M7.95 27.0375C6.875 25.8375 6.03125 24.4625 5.41875 22.9125C4.80625 21.3625 4.5 19.725 4.5 18C4.5 14.25 5.8125 11.0625 8.4375 8.4375C11.0625 5.8125 14.25 4.5 18 4.5V1.5L25.5 7.125L18 12.75V9.75C15.725 9.75 13.7812 10.5563 12.1688 12.1688C10.5563 13.7812 9.75 15.725 9.75 18C9.75 19.15 9.96875 20.225 10.4062 21.225C10.8438 22.225 11.4375 23.1 12.1875 23.85L7.95 27.0375ZM18 34.5L10.5 28.875L18 23.25V26.25C20.275 26.25 22.2188 25.4438 23.8312 23.8312C25.4438 22.2188 26.25 20.275 26.25 18C26.25 16.85 26.0312 15.775 25.5938 14.775C25.1562 13.775 24.5625 12.9 23.8125 12.15L28.05 8.9625C29.125 10.1625 29.9688 11.5375 30.5813 13.0875C31.1938 14.6375 31.5 16.275 31.5 18C31.5 21.75 30.1875 24.9375 27.5625 27.5625C24.9375 30.1875 21.75 31.5 18 31.5V34.5Z"
                          fill="#E74390"
                        />
                      </g>
                    </svg>
                  </Box>
                </Grid>
                <Grid sx={{ alignContent: "flex-start" }} container item xs={9}>
                  <SubHeading style={{ marginBottom: "8px" }}>
                    Sync your Instagram uploads with your Google business
                    profile(GBP)
                  </SubHeading>
                  <Paragraph>
                    Post on Instagram, and Chrone will automatically update your
                    Google Business Profile (GBP) with latest photos! You can
                    always choose which pictures don’t appear on Google <br />
                    <br />
                    And keep your GBP fresh without any extra efforts!
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <FixedBottom
        onClickSkip={onHandleSkip}
        showSkipText={true}
        loader={loading}
        nextEnabled={true}
        onClickNext={onClickNext}
        buttonText="CONNECT INSTAGRAM"
        fillPercent={80}
      />
      {/* <div
        style={{
          bottom: 0,
          position: "fixed",
          width: "100%",
          zIndex: 9999,
        }}>
        <ProgressBar value={80} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Grid
            sx={{ alignItems: "center", display: "flex", cursor: "pointer" }}
            item
            xs={6}>
            <div
              onClick={onHandleSkip}
              style={{
                color: "#000000",
                fontWeight: "800",
                fontSize: "14px",
                cursor: "pointer",
                padding: "1rem",
              }}>
              <Paragraph
                style={{
                  color: "#000000",
                  fontWeight: "800",
                  fontSize: "14px",
                }}>
                Skip
              </Paragraph>
            </div>
          </Grid>
          <Grid
            sx={{ alignContent: "center", cursor: "pointer" }}
            container
            item
            xs={6}>
            <PrimaryButton
              disabled={false}
              style={{ fontSize: "14px", padding: "8px 12px" }}
              onClick={async () => {
                mixPanelTrackEvent("connect_instagram_click", {
                  type: "Click",
                });
                await sendSlackNotification(
                  base_slack_channel,
                  `Connect Instagram Clicked`
                );
                instaOauth();
              }}>
              CONNECT INSTAGRAM
            </PrimaryButton>
          </Grid>
        </div>
      </div> */}
    </div>
  );
}

export default InstagramConnect;
