import React, { useState } from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Heading from "../utils/typography/Heading";
import HeaderBack from "../utils/HeaderBack";
import FixedBottom from "../utils/FixedBottom";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { useNavigate } from "react-router-dom";
import { reviewAutomationPreferences } from "../apis/reviewAutomationPreferences";
import postOnboardingStage from "../apis/postOnboardingStage";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";
import OptionBox from "../utils/OptionBox";

function ClientCount() {
  const options = [
    { heading: "Less than 50", option: "0-50" },
    { heading: "50 - 150", option: "50 - 150" },
    { heading: "150 - 300", option: "150 - 300" },
    { heading: "300 - 500", option: "300 - 500" },
    { heading: "500+", option: "500+" },
  ];
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSelectOption = (e) => {
    setSelectedOption(e.target.value);
  };
  const navigate = useNavigate();
  const onClickNext = async () => {
    setLoading(true);
    mixPanelTrackEvent("client_count", {
      type: "Click",
    });
    await sendSlackNotification(
      base_slack_channel,
      `Client count: ${selectedOption} \nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
    );
    await reviewAutomationPreferences(
      "client_count_range",
      selectedOption,
      localStorage["uid"]
    );
    await postOnboardingStage(
      "review-consent",
      localStorage["uid"],
      localStorage["email"]
    );
    setLoading(false);
    setSelectedOption("");
    window.location.href = "/review-consent";
  };
  return (
    <div style={{ paddingVertica: "16px" }}>
      <HeaderBack
        back={() => {
          navigate(-1);
        }}
        style={{ backgroundColor: "#F0F0F0" }}
      />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"8px"}
          style={{ textAlign: "center" }}>
          How many clients do you have?
        </Heading>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5vh",
          }}>
          <OptionBox
            handleSelectOption={handleSelectOption}
            selectedOption={selectedOption}
            options={options}
          />
        </div>
      </Container>
      <FixedBottom
        loader={loading}
        nextEnabled={selectedOption !== "" ? true : false}
        onClickNext={onClickNext}
        buttonText="NEXT"
        fillPercent={60}
      />
    </div>
  );
}

export default ClientCount;
