import React, { useContext } from "react";
import PrimaryButton from "../../../utils/Button";
import { CounterContext } from "../../context/counter-context";

export default function ServiceButtons() {
  const [state, dispatch] = useContext(CounterContext);

  const addService = () => {
    dispatch({action: "add_service"});
  };

  const deleteService = () => {
    dispatch({action: "remove_service"});
  };

  return (
    <div>
      <div>
        <PrimaryButton disabled={false} color="green" onClick={addService}>
          Add Service
        </PrimaryButton>
        <PrimaryButton disabled={false} color="red" onClick={deleteService}>
          Minus Service
        </PrimaryButton>
      </div>
    </div>
  );
}