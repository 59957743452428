import { Typography } from '@mui/material';

const Paragraph = (props) => {
    const paraStyle = {
        fontFamily: 'Red Hat Display',
        fontStyle: 'normal',
        fontWeight: `${props.font_weight ?? 400}`,
        fontSize: `${props.font_size ?? 12}px`,
            lineHeight: `${props.line_height ?? 18}px`,
        color: '#555B5B'
    }
    
    return(
        <Typography mt={props.mt} sx={{...paraStyle, ...props.style}}  variant="p" component="p" >
            {props.children}
        </Typography>
    );
}

export default Paragraph;