import HeaderCross from "../utils/HeaderCross";
import Heading from "../utils/typography/Heading";
import { Container } from "@mui/material";
import ServiceEditForm from "./ServiceEditForm";
import { useContext, useEffect } from "react";
import base_url from "../backendUrl";
import { ServicesContext } from "./context/context";
import { GlobalContext } from '../context/Global';
import { mixPanelTrackEvent } from "../utils/mixpanel";


export default function NewServiceDetail(){
    const [globals] = useContext(GlobalContext);
    const [states, setStates] = useContext(ServicesContext)
    let new_service = states.service;
    
    const addService = async (service) => {
        console.log(service)
        let payload = service;
        payload["place_id"] = localStorage["place_id"];
        const url = base_url+'/add-service';

        const response = await fetch(url, {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(payload)
        });
        setStates({
            action : 'change_screen_snackbar',
            services: states.services,
            screen_no : 1,
            showSnackbar : true
        })
    };

    useEffect(() => {
        mixPanelTrackEvent('new_service_add_screen_open', {
            type: 'Page Open'
        })
    }, [])
       
    return(
        <div style={{width: globals.width}}>
            <Container>
                <HeaderCross  back={()=>setStates({action : 'change_screen',screen_no : states.prev})} style={{marginTop:10}}/>
                <Heading>Add service details</Heading>
                <ServiceEditForm text={'ADD SERVICE'} service={new_service} updateService={addService}/>
            </Container>
        </div>
    )
}