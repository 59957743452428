import React, { useState } from "react";
import {
  Container,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Heading from "../utils/typography/Heading";
import HeaderBack from "../utils/HeaderBack";
import FixedBottom from "../utils/FixedBottom";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { reviewAutomationPreferences } from "../apis/reviewAutomationPreferences";
import postOnboardingStage from "../apis/postOnboardingStage";
import { useNavigate } from "react-router-dom";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";
import OptionBox from "../utils/OptionBox";

export default function ReviewAutomationConsent() {
  const options = [
    {
      heading: "Automatically send review request and keep me informed",
      option: "automated",
    },
    {
      heading: "Always take my approval before review requests",
      option: "manual",
    },
  ];
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSelectOption = (e) => {
    setSelectedOption(e.target.value);
  };
  const onClickNext = async () => {
    setLoading(true);
    mixPanelTrackEvent("review_consent", {
      type: "Click",
    });
    await sendSlackNotification(
      base_slack_channel,
      `Review consent: ${selectedOption}\nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
    );
    await reviewAutomationPreferences(
      "review_automation_consent",
      selectedOption,
      localStorage["uid"]
    );
    await postOnboardingStage(
      "setNumber",
      localStorage["uid"],
      localStorage["email"]
    );
    setLoading(false);
    setSelectedOption("");
    window.location.href = "onboard/photos-storage";
  };

  return (
    <div style={{ paddingVertica: "16px" }}>
      <HeaderBack
        back={() => {
          navigate(-1);
        }}
        style={{ backgroundColor: "#F0F0F0" }}
      />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"8px"}
          style={{ textAlign: "center" }}>
          How would you like us to send the review requests?
        </Heading>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5vh",
          }}>
          <FormControl component="fieldset">
            <FormLabel component="legend" />

            <RadioGroup
              name="options"
              value={selectedOption}
              onChange={handleSelectOption}>
              {options.map((item, index) => (
                <div key={item.id || index}>
                  <div
                    className="location-box"
                    style={{
                      borderRadius: "20px",
                      borderWidth: "1.5px",
                      borderStyle: "solid",
                      borderColor:
                        selectedOption == item.option ? "#C14296" : "#CED0D0",
                      marginBottom: "16px",
                    }}>
                    <FormControlLabel
                      style={{
                        padding: "10px 30px 10px 10px",
                      }}
                      value={item.option}
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": { color: "black" },
                          }}
                        />
                      }
                      label={item.heading}
                    />
                  </div>
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </Container>
      <FixedBottom
        loader={loading}
        nextEnabled={selectedOption !== "" ? true : false}
        onClickNext={onClickNext}
        buttonText="NEXT"
        fillPercent={70}
      />
    </div>
  );
}
