import { Typography } from '@mui/material';
import { GlobalContext } from '../../context/Global';
import { useContext } from 'react';


const SubHeading = (props) => {
    const [globals] = useContext(GlobalContext);

    const headingStyle = {
        fontFamily: props.noBold ? 'Red Hat Display' : 'Red Hat Display bold',
        fontStyle: 'normal',
        fontWeight: `${globals.browser=='Apple Safari'?" 400":"700"}`,
        fontSize: `${props.font_size ?? 16}px`,
        lineHeight: `${props.line_height ?? 24}px`,
        color: '#000000'
    }

    return(
        <Typography data-value={props.value} mt={props.mt} sx={{...headingStyle, ...props.style}}  variant="h2" component="h2" >
            {props.children}
         </Typography>
    );
}

export default SubHeading;