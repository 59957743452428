import { BrowserRouter as Router } from "react-router-dom";
import { GlobalContextProvider } from "../context/Global";
import AnimatedRoutes from "./AnimatedRoutes";
import { clarity } from "react-microsoft-clarity";

function App() {
  clarity.init("m2kjhoguaw");
  return (
    <div className="App">
      <GlobalContextProvider>
        <Router>
          <AnimatedRoutes />
        </Router>
      </GlobalContextProvider>
    </div>
  );
}

export default App;
