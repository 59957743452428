import mixpanel from 'mixpanel-browser';

mixpanel.init('05684f5f902260133df342d76518e2ab', {debug: true});

export const mixPanelIdentifyUser = id => {
  mixpanel.identify(id)
}

export const mixPanelTrackEvent = (event, event_data) => {
  mixpanel.track(event, event_data);
}
