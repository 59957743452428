
import { useEffect } from 'react';
import sendSlackNotification from '../utils/sendSlackNotification';


function Clear() {
    let urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        localStorage.clear()

        const src_tag = urlParams.get("src_tag") ?? null;
        if (src_tag != null) {
            localStorage.setItem('srcTag', src_tag);
        }
        //not required anymore here--- @vishalTimelyAI
        setTimeout(async () => {
            await sendSlackNotification("temp-channel", `Clear.js localStorage.getItem('srcTag'): ${localStorage.getItem('srcTag')}`)
            if (src_tag != null) {
                window.location.href = `${window.location.origin}/onboard?src_tag=${localStorage.getItem('srcTag')}`
            } else {
                window.location.href = `${window.location.origin}/onboard`
            }
        }, 500)
    }, []);

    return <div>
    </div>

}

export default Clear;