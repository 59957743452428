import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import SubHeading from '../utils/typography/SubHeading';
import Paragraph from '../utils/typography/Paragraph';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { styled } from '@mui/material/styles';
import { Dialog, Rating } from '@mui/material';
import ServiceEdit from './ServiceEdit';
import { fontSize } from '@mui/system';
import Star from '../assets/img/Star.svg';
import StarFilled from '../assets/img/StarFilled.svg';
import { mixPanelTrackEvent } from '../utils/mixpanel';


const StarServ = styled(
    (props) => {
        return <IconButton {...props} />;
    })
    (({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));


export default function ServiceCard({service, updateService, starservice, deleteService}) {
    const [starred, setStarred] = React.useState(service.star);
    const [edit, setEdit] = React.useState(false);
    // console.log(starred);
    
    const cardStyle = {
        minWidth: 275,
        marginTop: "16px",
        border: '1px solid #CED0D0',
        boxShadow: 'none',
        borderRadius: 3
    };

    const starredCardStyle = {
        border: '1px solid #A1409A',
        // background: 'linear-gradient(to right, #F5448F, #CC4294, #783E9D)'
    };

    const editService = () => {
        setEdit(true);
    };

    const cancelEdit = () => {
        setEdit(false);
    }

    let x = service.star;
    const StarServiceIcon = x ? <img src={StarFilled} /> : <img src={Star} />;

    return (
        <>
            <Card sx={{ ...cardStyle, ...(service.star ? starredCardStyle : {}) }}>
                <CardContent style={{paddingBottom: 0}} onClick={() => {
                    mixPanelTrackEvent('service_edit_click', {
                        type: 'Click',
                        category: 'card'
                    })
                    editService()
                }}>
                    <SubHeading font_size={16}>
                        {service.name}
                    </SubHeading>
                    <SubHeading mt={1} style={{fontSize: "12px", marginTop: "0px"}}>
                        $ {service.price_start} • 15 mins
                    </SubHeading>
                    <Paragraph mt={1} mb={1}>
                        {service.description}
                    </Paragraph>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" onClick={() => {
                        mixPanelTrackEvent('service_edit_click', {
                            type: 'Click',
                            category: 'edit_cta'
                        })
                        editService()
                    }}>
                        <ModeEditIcon sx={{ color: 'black', fontSize: '16px', fontWeight: "200" }} />
                        <SubHeading style={{ paddingLeft: '10px', fontSize: '12px', }}>Edit</SubHeading>
                    </IconButton>

                    <StarServ
                        aria-label="Rate"
                        onClick={() => {
                            mixPanelTrackEvent('service_star_click', {
                                type: 'Click'
                            })
                            starservice(service.service_id, !service.star);
                            setStarred(!x);
                        }}
                    >
                        {StarServiceIcon}
                        <SubHeading style={{ paddingLeft: '10px', fontSize: '12px', ...(service.star ? {color: "#A1409A"} : {}) }}>{!service.star ? 'Star' : 'Starred'}</SubHeading>
                    </StarServ>
                </CardActions>
            </Card>
            <Dialog
                fullScreen
                open={edit}
                onClose={setEdit} sx={{zIndex:13330}}
            >
                <ServiceEdit
                    cancelEdit={cancelEdit}
                    service={service}
                    updateService={updateService}
                    deleteService={deleteService}
                />
            </Dialog>
        </>
    );
}