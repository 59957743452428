import { Box, CircularProgress } from "@mui/material";
import { useEffect, useContext } from "react";
import base_url from "../backendUrl";
import HeaderBack from "../utils/HeaderBack";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { ServicesContext } from "./context/context";

function LoadingServices() {
  const [serviceStates, setServiceStates] = useContext(ServicesContext);

  function isMobileDevice() {
    return true;
    // window.innerWidth <= 768 && window.innerHeight <= 1024;
  }

  const fetchServices = async () => {
    let place_id = localStorage.getItem("place_id");
    const url = `${base_url}/get-services-list?place_id=${place_id}`;

    try {
      const response = await fetch(url);
      const json = await response.json();
      console.log(json);

      if (json.data.length === 0) {
        mixPanelTrackEvent("services_redirection", {
          type: "Redirection",
          booking_link: false,
        });
        setServiceStates({
          action: "change_screen",
          screen_no: 0,
        });
      } else {
        console.log(json.data);
        mixPanelTrackEvent("services_redirection", {
          type: "Redirection",
          booking_link: true,
        });
        if (window.location.href.includes('web.chrone') || localStorage["test_source"] === 'web.chrone.io')
          window.location.href = `${base_url}/old-dashboard-transit?place_id=${localStorage["place_id"]}&uid=${localStorage["uid"]}&location=${localStorage["location"]}`;
        else {
          window.location.href = `chrone://close-browser?uid=${localStorage["uid"]}`;
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // fetchServices();
    setServiceStates({
      action: "change_screen",
      screen_no: 0,
    });
  }, []);

  return (
    <Box style={{ marginBottom: "160px", width: "100%" }}>
      <HeaderBack style={{ backgroundColor: "#F0F0F0" }} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 30,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    </Box>
  );
}

export default LoadingServices;
