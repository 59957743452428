import { useEffect, useState } from "react";
import ConnectInsta from "./ConnectInsta";
import ConnectSquare from "./ConnectSquare";
import Banner from "./Banner";
import Priming from "./Priming";
import PrimingFinish from "./PrimingFinish";
import { GlobalContext } from "../context/Global";
import { useContext } from "react";
import { useTransition, animated } from "react-spring";
import SquareUsage from "./SquareUsage";

export const Wrapper = ({ styles, children }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // height: (globals.height),
        justifyContent: "space-between",
      }}>
      {children}
    </div>
  );
};
function ConnectSocial() {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const [state, setState] = useState(() => {
    //To get the state of priming we add state number in front of url while saving it in db for checking dropoff:
    // onboard/priming: Square-preference
    // onboard/priming?state-2: Connect-square

    if (
      window.location.href.includes("state-2") ||
      window.location.href.includes("square")
    )
      return 2;
    else return 1; //default square-preference
  }); //params["square"] ? 1 : 0);

  const [globals] = useContext(GlobalContext);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const uid = params.uid; // Get uid from URL parameters
    if (uid) {
      localStorage.setItem("uid", uid); // Store uid in local storage
    }
  }, [params]); // Run effect when params change

  const pageTransitions = useTransition(state, {
    from: { opacity: 1, transform: `translate3d(100%,0,0)` },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-100%,0,0)" },
    config: {
      duration: 200,
    },
    onStart: () => setIsTransitioning(true),
    onRest: () => setIsTransitioning(false),
  });

  const pages = {
    0: <Priming stateChanger={setState} new_state={1} />,
    1: (
      <Wrapper>
        <div>
          <Banner stateChanger={setState} new_state={0} backColor={"#F0F0F0"} />
          <SquareUsage
            stateChanger={(option) => {
              setState(option);
            }}
          />
        </div>
      </Wrapper>
    ),
    2: (
      <Wrapper>
        <Banner stateChanger={setState} new_state={1} backColor={"#F0F0F0"} />
        <ConnectSquare stateChanger={setState} new_state={4} />
      </Wrapper>
    ),
    3: (
      <Wrapper>
        <Banner stateChanger={setState} new_state={2} backColor={"#F0F0F0"} />
        <ConnectInsta stateChanger={setState} new_state={4} />
      </Wrapper>
    ),
    4: (
      <Wrapper>
        <PrimingFinish
          stateChanger={setState}
          new_state={1}
          backColor={"#FFFFFF"}
        />
      </Wrapper>
    ),
  };

  return (
    <div style={{ position: "relative" }}>
      {isTransitioning ? (
        pageTransitions((props, idx) => (
          <animated.div
            style={{
              ...props,
              width: globals.width,
              height: "100vh",
            }}>
            {pages[idx]}
          </animated.div>
        ))
      ) : (
        <div
          style={{
            width: globals.width,
            height: "100vh",
            marginBottom: "160px",
          }}>
          {pages[state]}
        </div>
      )}
    </div>
  );
}

export default ConnectSocial;
