import React, { useContext } from "react";
import { CounterContext } from "./context/counter-context";

export default function CounterDisplay() {
  const [state] = useContext(CounterContext);

  return (
    <>
      <div>{state.count}</div>
      <div>{Date().toString()}</div>
      <div>Counter</div>
    </>
  );
}