import React, { useState } from "react";
import Banner from "./Banner";
import { Container } from "@mui/material";
import Heading from "../utils/typography/Heading";
import Paragraph from "../utils/typography/Paragraph";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import FixedBottom from "../utils/FixedBottom";
import { useNavigate } from "react-router-dom";
import { reviewAutomationPreferences } from "../apis/reviewAutomationPreferences";
import postOnboardingStage from "../apis/postOnboardingStage";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";
import OptionBox from "../utils/OptionBox";

function AutomateReviews() {
  const options = [
    { heading: "Automate my reviews", option: "automate" },
    { heading: "I’ll get reviews myself", option: "manual-review-request" },
  ];
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSelectOption = (e) => {
    setSelectedOption(e.target.value);
  };
  const navigate = useNavigate();

  const onClickNext = async () => {
    setLoading(true);
    mixPanelTrackEvent("automate_review_options", {
      type: "Click",
    });
    if (selectedOption === "automate") {
      await sendSlackNotification(
        base_slack_channel,
        `Automate my reviews! \nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
      );
      await reviewAutomationPreferences(
        "review_invites_preference",
        selectedOption,
        localStorage["uid"]
      );
      await postOnboardingStage(
        "client-count",
        localStorage["uid"],
        localStorage["email"]
      );
      setLoading(false);
      window.location.href = "/client-count";
    } else if (selectedOption === "manual-review-request") {
      await sendSlackNotification(
        base_slack_channel,
        `Don't automate my reviews \nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
      );
      await reviewAutomationPreferences(
        "review_invites_preference",
        selectedOption,
        localStorage["uid"]
      );
      await postOnboardingStage(
        "reviews-pledge",
        localStorage["uid"],
        localStorage["email"]
      );
      setLoading(false);
      setSelectedOption("");
      window.location.href = "/reviews-pledge";
    }
  };

  return (
    <div style={{ paddingVertica: "16px" }}>
      <Banner showGoogleVariant backColor={"rgb(240, 240, 240)"} />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"8px"}
          style={{ textAlign: "center" }}>
          Get new 5-star Google reviews automatically
        </Heading>
        <Paragraph
          style={{
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
          }}>
          To improve Google ranking for your business,<br></br> Chrone
          automatically sends smart review requests to your clients every week
        </Paragraph>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5vh",
          }}>
          <OptionBox
            handleSelectOption={handleSelectOption}
            selectedOption={selectedOption}
            options={options}
          />
        </div>
      </Container>
      <FixedBottom
        loader={loading}
        nextEnabled={selectedOption !== "" ? true : false}
        onClickNext={onClickNext}
        buttonText="NEXT"
        fillPercent={55}
      />
    </div>
  );
}

export default AutomateReviews;
