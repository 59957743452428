import Autocomplete from '@mui/material/Autocomplete';
import { alpha, styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import * as React from 'react';




export default function DropdownSearch(props){
    const [value, setValue] = React.useState('');
    props.setCategory(value);

    const BookingLinkTextField = styled((props) => (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            disablePortal
            options={props.option}
            style = {props.style}
            {...props}
            renderInput={(params) => <TextField  {...params} label={props.label} />}
        />
    ))(({ theme }) => ({
        '& .MuiAutocomplete-inputRoot': {
        border: '1px solid #CED0D0',
        overflow: 'hidden',
        borderRadius: 12,
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : '#ffffff',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-focused': {
            border: '1px solid #CED0D0',
            backgroundColor: 'transparent',
            borderColor : '#CED0D0',
            color : '#CED0D0 !important'
            // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        },
        },
        '& .MuiOutlinedInput-notchedOutline' : {
            border : 'none !important',
            color : 'inherit !important'
        },
        '& .MuiFormLabel-filled' : {
            top : '18% !important',
            color : 'rgba(0, 0, 0, 0.6) !important'
        },
        '& .MuiInputLabel-root' : {
            color : 'rgba(0, 0, 0, 0.6) !important',
            '&.Mui-focused' : {
                top : '18% !important',
            }
        },
        '& .Mui-disabled' : {
        backgroundColor : 'rgba(240, 240, 240, 0.5)'
        },
    }));

    return(
        <BookingLinkTextField
            label={props.label}
            name = {props.name}
            id={props.id}
            {...props}
            style={{ marginTop: 16, width:'100%' }}
        />
    )
}
  