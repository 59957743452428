import React, { useState, createContext } from "react";

// Create Context Object
export const ServicesContext = createContext();

// Create a provider for components to consume and subscribe to changes
export const ServicesContextProvider = props => {
  const initState = {
    screen: 1,
    services: [],
    services_name: [],
    categories: [],
    service: '',
    prev: 1,
    showSnackbar: false,
    categories_services: {}
  }

  const [state, setState] = useState(initState);

  const dispatch = (payload) => {
    let new_service, new_services;

    switch (payload.action) {
      case "change_screen":
        setState({
          ...state,
          prev: state.screen,
          screen: payload.screen_no
        })
        break;
      case "set_categories":
        setState({
          ...state,
          categories: payload.categories
        })
        break;
      case "set_categories_services":
        setState({
          ...state,
          categories: payload.categories,
          categories_services: payload.categories_services
        })
        break;
      case "change_screen_service":
        setState({
          ...state,
          prev: state.screen,
          screen: payload.screen_no,
          service: payload.service
        })
        break;
      case "change_screen_snackbar":
        setState({
          ...state,
          services: payload.services,
          screen: payload.screen_no,
          showSnackbar: payload.showSnackbar
        })
        break;
      case "service":
        setState({
          ...state,
          service: payload.service
        })
        break;
      case "services_name":
        setState({
          ...state,
          services_name: payload.services_name
        })
        break;
      case "init_services":
        setState({
          ...state,
          services: payload.services
        })
        break;
      case "change_screen_init_services":
        setState({
          ...state,
          screen: payload.screen_no,
          services: payload.services
        })
        break;
      case "show_snackbar":
        setState({
          ...state,
          showSnackbar: payload.showSnackbar
        })
        break;
      default:
        setState(state);
    }
  }

  return (
    <ServicesContext.Provider value={[state, dispatch]}>
      {props.children}
    </ServicesContext.Provider>
  );
};