import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
const OptionBox = ({ handleSelectOption, selectedOption, options }) => {
  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend" />

        <RadioGroup
          name="options"
          value={selectedOption}
          onChange={handleSelectOption}>
          {options.map((item, index) => (
            <div key={item.option || index}>
              <div
                key={item.option}
                onClick={() =>
                  handleSelectOption({ target: { value: item.option } })
                }
                className="location-box"
                style={{
                  borderRadius: "20px",
                  borderWidth: "1.5px",
                  borderStyle: "solid",
                  borderColor:
                    selectedOption == item.option ? "#C14296" : "#CED0D0",
                  marginBottom: "16px",
                  padding: "0px 30px 0px 10px",
                }}>
                <FormControlLabel
                  value={item.option}
                  control={
                    <Radio
                      sx={{
                        color: "black",
                        "&.Mui-checked": { color: "black" },
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        paddingTop: "8px", // Add padding to the top
                        paddingBottom: "8px", // Add padding to the bottom
                        display: "block", // Ensure the padding is applied properly
                      }}>
                      {item.heading}
                    </span>
                  }
                />
              </div>
            </div>
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default OptionBox;
