import React, { useState, useContext } from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
} from "@mui/material";
import Heading from "../utils/typography/Heading";
import HeaderBack from "../utils/HeaderBack";
import FixedBottom from "../utils/FixedBottom";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { useNavigate } from "react-router-dom";
import { reviewAutomationPreferences } from "../apis/reviewAutomationPreferences";
import postOnboardingStage from "../apis/postOnboardingStage";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";
import { GlobalContext } from "../context/Global";
import CheckBox from "../utils/CheckBox";

function Workphotos() {
  const [globals, setGlobals] = useContext(GlobalContext);

  const options = [
    { heading: "Instagram", option: "Instagram" },
    { heading: "Facebook", option: "Facebook" },
    { heading: "Tiktok", option: "Tiktok" },
    {
      heading: "Yelp",
      option: "Yelp",
    },
    {
      heading: "Current device gallery",
      option: "Current device gallery",
    },
    {
      heading: "Other device",
      option: "Other device",
    },
    {
      heading: "I don’t have work photos",
      option: "I dont have work photos",
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelectOption = (e) => {
    const value = e.target.value;
    if (selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };
  const handleBoxClick = (option) => {
    handleSelectOption({ target: { value: option } });
  };

  const navigate = useNavigate();

  const onClickNext = async () => {
    setLoading(true);

    const mediaStoragePlatform = selectedOptions;
    mixPanelTrackEvent("store_your_media", {
      type: "Click",
    });
    await sendSlackNotification(
      base_slack_channel,
      `Media storage platform: ${mediaStoragePlatform} \nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
    );
    await reviewAutomationPreferences(
      "media_storage_platform",
      JSON.stringify(mediaStoragePlatform),
      localStorage["uid"]
    );
    await postOnboardingStage(
      "setNumber",
      localStorage["uid"],
      localStorage["email"]
    );

    setLoading(false);
    setSelectedOptions([]);

    if (selectedOptions.includes("Instagram")) {
      window.location.href = localStorage["uid"]
        ? `/onboard/connect-instagram?uid=${localStorage["uid"]}`
        : "/onboard/connect-instagram";
    } else {
      window.location.href = "/setNumber";
    }
  };

  return (
    <div style={{ paddingVertica: "16px" }}>
      <HeaderBack
        back={() => {
          navigate(-1);
        }}
        style={{ backgroundColor: "#F0F0F0" }}
      />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"8px"}
          style={{ textAlign: "center" }}>
          Where do you store your work photos?
        </Heading>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5vh",
          }}>
          <CheckBox
            handleSelectOption={handleSelectOption}
            selectedOptions={selectedOptions}
            options={options}
            handleBoxClick={handleBoxClick}
          />
        </div>
      </Container>
      <FixedBottom
        loader={loading}
        nextEnabled={selectedOptions.length > 0}
        onClickNext={onClickNext}
        buttonText="NEXT"
        fillPercent={70}
      />
    </div>
  );
}

export default Workphotos;
