import { useContext, useEffect, useState } from 'react';
import logo from '../assets/img/logo.svg';
import back from '../assets/img/onboarding_back.png';
import { GlobalContext } from '../context/Global';
import PrimaryButton from '../utils/Button';
import { mixPanelTrackEvent } from '../utils/mixpanel';
import Paragraph from '../utils/typography/Paragraph';
import sendSlackNotification from '../utils/sendSlackNotification';
import { base_slack_channel } from '../backendUrl';


function Home(props) {
  const [globals] = useContext(GlobalContext);

  useEffect(() => {
    mixPanelTrackEvent('landing_screen_open', {
      type: 'Page Open'
    })

    sendSlackNotification("temp-channel", `Home.js localStorage.getItem('srcTag'): ${localStorage.getItem('srcTag')}`)
  }, [])
  
  return (
    <div style={{
      height: globals.height,
      width: globals.width,
      display: "flex",
      flexDirection: "column-reverse",
      background: `url(${back}) no-repeat`,
      backgroundColor: "rgba(100,100,100,.4)",
      backgroundSize: "contain",
      margin: "auto"
    }}>
      <div className='home-bottom-bottom'>
        <h1 className={`h1 centered-text${globals.browser=='Apple Safari'?" apple-bold":""}`}> Welcome <br /> {props.biz_name}</h1>
        <Paragraph style={{fontSize:'16px', lineHeight:'24px', textAlign: 'center'}}>
        We're here to turbocharge your business on Google, drawing a swarm of new clients to fuel your success! Let's dive in and thrive together! 🌟🔥
        </Paragraph>
        <div className='centered-button' style={{flexDirection: "column", alignItems: "center"}}>
          <Paragraph style={{width: "100%", fontSize:'16px', textAlign: "center"}}>Click below</Paragraph>
          <PrimaryButton
            style={{height: 52, fontSize: 14, lineHeight: 20, letterSpacing: "4%", marginTop: "5px"}}
            disabled={props.redirect_url=='/' ? true : false}
            onClick={() => {
              mixPanelTrackEvent('get_started_btn_click', {
                type: "Click",
                srctag: localStorage.getItem("srcTag")
              })
              sendSlackNotification(base_slack_channel, 'Get Started Clicked')
              props.stateChanger(2)
            }}
          >
            GET STARTED
          </PrimaryButton>
        </div>
      </div>
      <div className='home-bottom-top'>
      </div>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
    </div>
  );
}

export default Home;
