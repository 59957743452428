import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Union from "../assets/img/Union.svg";
import { useNavigate } from "react-router-dom";

const HeaderBack = (props) => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: 14,
        backgroundColor: props.backColor,
      }}
      className="flex-space-header">
      <span
        style={{
          width: "65px",
          display: "flex",
          justifyContent: "flex-start",
        }}>
        <ArrowBackIosNewIcon
          onClick={() => {
            if (typeof props.back === "function") {
              props.back();
            } else navigate(-1);
          }}
          style={{
            display: "flex",
            alignSelf: "center",
            height: "16px",
            cursor: "pointer",
          }}
        />
      </span>
      <span
        style={{ width: "65px", display: "flex", justifyContent: "center" }}>
        <img className="logo-sm" src={Union} alt="logo" />
      </span>
      <p
        style={{ width: "65px", textAlign: "right", cursor: "pointer" }}
        className="help"
        onClick={() => {
          window.location.href = "tel:+12155150601";
        }}>
        Help?
      </p>
    </div>
  );
};

export default HeaderBack;
