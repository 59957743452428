import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { mixPanelTrackEvent } from './mixpanel';

export default function SearchBar(props) {
  return (
    <Paper
      component="form"
      sx={{mt:2, p: '2px 4px', display: 'flex', alignItems: 'center', width: '58%', background:'#FFFFFF',
      borderRadius: '12px' ,boxShadow: '0px 0px 28px rgba(59, 59, 59, 0.12)', ...props.style }}
    >
      <InputBase
        sx={{ ml: 1, flex:1}}
        placeholder="Search "
        inputProps={{ 'aria-label': 'search services' }}
        value={props.value}
        onChange = {props.onChange}
        onClick = {() => {
          mixPanelTrackEvent('service_search_input_click', {
            type: 'Click'
          })
        }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <SearchIcon />
      </IconButton>
     
    </Paper>
  );
}