import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

export default function ProgressBar(props) {
  return (
    <Box mt={props.mt} sx={{ width: '100%' }}>
      <ThemeProvider theme={theme}>
      <BorderLinearProgress sx={{backgroundColor:'#f0f0f0', height:'8px'}} color="primary" variant="determinate" value={props.value} />
      </ThemeProvider>
    </Box>
  );
}
