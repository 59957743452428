import React, { useState } from "react";
import Banner from "./Banner";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import Heading from "../utils/typography/Heading";
import Paragraph from "../utils/typography/Paragraph";
import HeaderBack from "../utils/HeaderBack";
import PrimaryButton from "../utils/Button";
import ProgressBar from "../utils/Progress";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { useNavigate } from "react-router-dom";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";

export default function ReviewsPledge() {
  const navigate = useNavigate();
  return (
    <div style={{ paddingVertica: "16px" }}>
      <Banner showGoogleVariant />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"8px"}
          style={{ textAlign: "center" }}>
          Take the pledge to get Google reviews yourself
        </Heading>
        <Paragraph style={{ fontSize: "16px", lineHeight: "24px" }}>
          In order to get more clients through Google, you will need to get{" "}
          <b>at least 1-2 reviews every week</b>
          <br />
          <br />
          Chrone will send you reminder notifications every week to achieve your
          goal :)
          <br />
          <br />
          Let’s get those reviews!
          <br />
        </Paragraph>
      </Container>
      {/* <div
        style={{
          bottom: 0,
          position: "fixed",
          width: "100%",
        }}>
        <ProgressBar value={70} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
          <Grid
            sx={{ alignItems: "center", display: "flex", cursor: "pointer" }}
            item
            xs={6}>
            <div
              style={{
                color: "#000000",
                fontWeight: "800",
                fontSize: "14px",
                cursor: "pointer",
                padding: "1rem",
              }}></div>
          </Grid>
          <Grid
            sx={{ alignContent: "center", cursor: "pointer" }}
            container
            item
            xs={6}>
            <PrimaryButton
              disabled={false}
              style={{ fontSize: "14px" }}
              onClick={async () => {
                mixPanelTrackEvent("reviews_pledge", {
                  type: "Click",
                });
              }}>
              CONFIRM
            </PrimaryButton>
          </Grid>
        </div>
      </div> */}
      <div
        style={{
          bottom: 0,
          position: "fixed",
          left: 0,
          right: 0,
          width: "100%",
        }}>
        <ProgressBar value={45} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0px 10px",
          }}>
          <Grid
            sx={{ alignItems: "center", display: "flex", cursor: "pointer" }}
            item
            xs={6}>
            <div
              style={{
                color: "#000000",
                fontWeight: "800",
                fontSize: "14px",
                cursor: "pointer",
                padding: "1rem",
              }}></div>
          </Grid>
          <Grid
            sx={{ alignContent: "center", cursor: "pointer" }}
            container
            item
            xs={6}>
            <PrimaryButton
              disabled={false}
              style={{ fontSize: "14px" }}
              onClick={async () => {
                mixPanelTrackEvent("reviews_pledge", {
                  type: "Click",
                });
                await sendSlackNotification(
                  base_slack_channel,
                  `Reviews pledge accepted\nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
                );
                window.location.href = "/onboard/photos-storage";
              }}>
              CONFIRM
            </PrimaryButton>
          </Grid>
        </div>
      </div>
    </div>
  );
}
