import Heading from "../utils/typography/Heading";
import Paragraph from "../utils/typography/Paragraph";
import FormControl from "@mui/material/FormControl";
import Highlight from "../utils/Highlight";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../utils/InputField";
import { Container } from "@mui/material";
import { useState, useEffect } from "react";
import { mixPanelTrackEvent } from "../utils/mixpanel";

function BookingLink({
  hc,
  setNextEnabled,
  setBookingLink,
  bookingLink,
  selectedOtherBookingPlatform,
  onChangebookingPlatformInput,
  bookingPlatformInput,
  bookingLinkInput,
}) {
  const handleLinkInputChange = (e) => {
    setBookingLink(e.target.value);
    if (
      e.target.value.length > 0 &&
      bookingPlatformInput &&
      bookingPlatformInput.length > 0
    ) {
      setNextEnabled(true);
    } else {
      setNextEnabled(false);
    }
  };

  const trackBookingLinkInputClick = (e) => {
    mixPanelTrackEvent("booking_link_input_click", {
      type: "Click",
    });
  };

  const trackOtherBookingPlatform = (e) => {
    mixPanelTrackEvent("booking_platform_other_input_click", {
      type: "Click",
    });
  };
  const handlePlatformInputChange = (e) => {
    onChangebookingPlatformInput(e);
    if (
      e.target.value.length > 0 &&
      bookingLinkInput &&
      bookingLinkInput.length > 0
    )
      setNextEnabled(true);
    else setNextEnabled(false);
  };
  return (
    <Container>
      <CloseIcon style={{ display: "flex", marginTop: "20px" }} onClick={hc} />

      <Heading mt={4}>Enter your booking link</Heading>
      <Paragraph
        style={{ textAlign: "center", fontSize: "16px", lineHeight: "24px" }}>
        We can fetch your service later on with link
      </Paragraph>

      {selectedOtherBookingPlatform && (
        <FormControl variant="standard" style={{ width: "100%" }}>
          <InputField
            label="Booking platform"
            defaultValue=""
            onClick={trackOtherBookingPlatform}
            onChange={handlePlatformInputChange}
          />
        </FormControl>
      )}

      <FormControl variant="standard" style={{ width: "100%" }}>
        <InputField
          label="Booking link"
          defaultValue=""
          value={bookingLink}
          onClick={trackBookingLinkInputClick}
          onChange={handleLinkInputChange}
        />
      </FormControl>

      <Highlight mt={3} style={{ padding: "12px 0" }}>
        Copy booking link directly from the app or website
        <br />
        {/* <a style={{color : '#F5448F'}}>Take me to the app</a> */}
      </Highlight>
    </Container>
  );
}

export default BookingLink;
