import React, { useState } from "react";
import { Container } from "@mui/material";
import Heading from "../utils/typography/Heading";
import HeaderBack from "../utils/HeaderBack";
import FixedBottom from "../utils/FixedBottom";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import { useNavigate } from "react-router-dom";
import { reviewAutomationPreferences } from "../apis/reviewAutomationPreferences";
import postOnboardingStage from "../apis/postOnboardingStage";
import sendSlackNotification from "../utils/sendSlackNotification";
import { base_slack_channel } from "../backendUrl";
import { GlobalContext } from "../context/Global";
import { useContext } from "react";
import OptionBox from "../utils/OptionBox";
function Clientstore() {
  const [globals, setGlobals] = useContext(GlobalContext);

  const options = [
    { heading: "Phone contacts", option: "Phone contacts" },
    { heading: "Offline spreadsheet", option: "Offline spreadsheet" },
    { heading: "Online spreadsheet", option: "Online spreadsheet" },
    {
      heading: "Physical register/notebook",
      option: "Physical register/notebook",
    },
    {
      heading: "I don’t store client details",
      option: "I dont store client details",
    },
    {
      heading: "Other",
      option: "Other",
    },
  ];
  const [inputValue, setInputValue] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const handleSelectOption = (e) => {
    setSelectedOption(e.target.value);
    // Show popup if selectedOption is "other"
    if (e.target.value.toLowerCase() === "other" && !isPopupVisible) {
      setIsPopupVisible(true);
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleCrossClick = () => {
    setAnimateOut(true);
    setSelectedOption("");
    setTimeout(() => {
      setIsPopupVisible(false);
      setAnimateOut(false);
    }, 300); // Duration of the fade-out animation
  };

  const navigate = useNavigate();
  const onClickNext = async () => {
    setLoading(true);
    const clientStoragePlatform =
      selectedOption.toLowerCase() === "other" ? inputValue : selectedOption;
    mixPanelTrackEvent("store_your_client", {
      type: "Click",
    });
    await sendSlackNotification(
      base_slack_channel,
      `Client storage platform: ${clientStoragePlatform} \nBiz Name - ${localStorage["biz_name"]}\nuid - ${localStorage["uid"]}`
    );
    await reviewAutomationPreferences(
      "client_storage_platform",
      clientStoragePlatform,
      localStorage["uid"]
    );
    await postOnboardingStage(
      "automate-reviews",
      localStorage["uid"],
      localStorage["email"]
    );
    setLoading(false);
    setSelectedOption("");
    window.location.href = "/automate-reviews";
  };
  return (
    <div style={{ paddingVertica: "16px" }}>
      <HeaderBack
        back={() => {
          navigate(-1);
        }}
        style={{ backgroundColor: "#F0F0F0" }}
      />
      <Container style={{ paddingBottom: "200px" }}>
        <Heading
          paddingTop={"32px"}
          paddingBottom={"8px"}
          style={{ textAlign: "center" }}>
          So where do you store your clients?
        </Heading>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "5vh",
          }}>
          <OptionBox
            options={options}
            selectedOption={selectedOption}
            handleSelectOption={handleSelectOption}
          />
        </div>
      </Container>
      {isPopupVisible ? (
        <div
          className="popup-overlay"
          style={{
            background: "rgba(0, 0, 0, 0.5)",
          }}>
          <div
            className={` ${animateOut ? "fade-out" : "fade-in"}`}
            style={{
              maxWidth: "600px",
              width: "100vw",
              transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              paddingTop: "16px",
              paddingLeft: "40px",
              paddingRight: "40px",
              margin: 0,
              position: "fixed",
              bottom: "97px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffffff",
              flexDirection: "column",
              borderRadius: "4px",
              boxShadow:
                "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
            }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                width: "100%",
                cursor: "pointer",
                marginLeft: "25px",
              }}>
              <svg
                onClick={handleCrossClick}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24">
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "32px",
                width: "414px",
              }}>
              <h1
                style={{
                  fontFamily: "Red Hat Display bold",
                  color: "#000",
                  textAlign: "center",
                  fontSize: "24px",
                  fontStyle: "normal",
                  height: "auto",
                  fontWeight: 700,
                  lineHeight: "normal",
                  width: "80%",
                  margin: 0,
                }}>
                So where do you store your clients?
              </h1>
            </div>
            <input
              style={{
                height: "1.4375em",
                fontSize: "16px",
                marginTop: "32px",
                marginBottom: "24px",
                borderRadius: "12px",
                border: "1px solid #ced0d0",
                width: "80%",
                paddingTop: "16px",
                paddingRight: "12px",
                paddingBottom: "16px",
                outline: "none",
                paddingLeft: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              type="text"
              name=""
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your input"
              id=""
            />
          </div>
        </div>
      ) : null}
      <FixedBottom
        loader={loading}
        nextEnabled={
          selectedOption !== "" &&
          (selectedOption.toLowerCase() !== "other" || inputValue.trim() !== "")
        }
        onClickNext={onClickNext}
        buttonText="NEXT"
        fillPercent={45}
      />
    </div>
  );
}

export default Clientstore;
