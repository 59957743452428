import { Container } from "@mui/system";
import PrimaryButton from "./Button";
import ProgressBar from "./Progress";
import Grid from "@mui/material/Grid";
import { GlobalContext } from "../context/Global";
import { useContext } from "react";
import { Button, CircularProgress } from "@mui/material";

const FixedBottom = (props) => {
  const [globals] = useContext(GlobalContext);

  const fixedBottom = {
    position: "fixed",
    // left: (window.innerWidth - globals.width) / 2,
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    backgroundColor: "#ffffff",
    color: "white",
    textAlign: "center",
    zIndex: "9999",
  };

  return (
    <div className="fixed_button" style={{ ...fixedBottom }}>
      <div className="fixed_button_inner">
        <ProgressBar mt={0} value={props.fillPercent} />
        <Container>
          <Grid sx={{}} container>
            <Grid sx={{ alignItems: "center", display: "flex" }} item xs={5}>
              {props.showSkipButton && (
                <span
                  style={{
                    color: "#000000",
                    margin: 0,
                    fontFamily: "Red Hat Display",
                    fontWeight: "800",
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontStyle: "normal",
                  }}
                  onClick={props.onClickSkip}>
                  Skip
                </span>
              )}

              {props.showSkipText && (
                <span
                  style={{
                    color: "#000000",
                    margin: 0,
                    fontFamily: "Red Hat Display",
                    fontWeight: "800",
                    fontSize: "14px",
                    lineHeight: "18px",
                    fontStyle: "normal",
                  }}
                  onClick={props.onClickSkip}>
                  Skip
                </span>
              )}
            </Grid>
            <Grid
              sx={{ alignContent: "center", justifyContent: "end" }}
              container
              item
              xs={7}>
              <PrimaryButton
                enabled={props.nextEnabled ? true : false}
                style={{
                  height: "max-content",
                  fontSize: "12px",
                  minWidth: "140px",
                  maxWidth: "200px",
                }}
                onClick={props.onClickNext}>
                {props.buttonText ?? "NEXT"}
                {props.loader ? (
                  <CircularProgress
                    style={{
                      width: "20px",
                      height: "20px",
                      color: "#000000",
                    }}
                  />
                ) : null}
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default FixedBottom;
