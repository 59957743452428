import axios from "axios";
import base_url from "../backendUrl";

const getOnboardingStage = async (uid) => {
  // console.log(uid)
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${base_url}/onboarding-log?uid=${uid}`,
      headers: {},
    };

    const response = await axios.request(config);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export default getOnboardingStage;
