import google from '../assets/img/google.svg';
import GoogleButton from '../utils/GoogleButton';
import HeaderEmpty from '../utils/HeaderEmpty';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Highlight from '../utils/Highlight';
import Paragraph from '../utils/typography/Paragraph';
import SubHeading from '../utils/typography/SubHeading';
import Heading from '../utils/typography/Heading';
import { useContext, useEffect, useState } from 'react';
import { Badge } from '@mui/material';
import gmail from '../assets/img/gmail.svg';
import error from '../assets/img/error.svg';
import { GlobalContext } from '../context/Global';
import { useTransition, animated, useSpringRef } from 'react-spring';
import { mixPanelTrackEvent } from '../utils/mixpanel';


const commonStyles = {
    background: '#F0F0F0',
    width: '40px',
    height: '40px',
};

function WrongAccount() {
    const [globals] = useContext(GlobalContext);
    const [params, setParams] = useState({
        picture: "",
        first_name: "",
        last_name: "",
        used_email: ""
    });
    // const state = 0;

    // const pageTransitions = useTransition(
    //     state,
    //     {
    //         from: { opacity: 1, transform: `translate3d(100%,0,0)` },
    //         enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
    //         leave: { opacity: 0.4, transform: 'translate3d(-100%,0,0)' },
    //         config: {
    //             duration: 400,
    //         },
    //     },
    // )

    useEffect(() => {
        setParams(new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        }));

        mixPanelTrackEvent('wrong_acc_select_open', {
            type: 'Page Open'
        })
    }, [])

    return (
        <div style={{
            height: globals.height,
            width: globals.width,
            display: "flex",
            flexDirection: "column-reverse",
        }}>
            <div className='side-padding' style={{
                minHeight: "45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
            }}>
                <div>
                    <Heading paddingTop={"32px"} paddingBottom={"24px"}>Your business is not linked to the email selected</Heading>
                    <Paragraph font_size={12} line_height={16} style={{ marginTop: "-4px", marginBottom: "8px", letterSpacing: "0.48px" }}>
                        Email selected :
                    </Paragraph>

                    <Highlight style={{ color: '#000000' }}>
                        <Grid container>
                            <Grid item xs={3}>
                                <Box sx={{
                                    ...commonStyles,
                                    borderRadius: '50%',
                                    backgroundImage: `url(${params["picture"]})`,
                                    backgroundSize: "contain"
                                }}>
                                </Box>
                            </Grid>
                            <Grid sx={{ alignContent: 'center' }} container item xs={9}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column"
                                }}>
                                    <SubHeading>{params["first_name"] + " " + params["last_name"]}</SubHeading>
                                    <Paragraph style={{ letterSpacing: "0.48px" }}>{params["used_email"]}</Paragraph>
                                </div>
                            </Grid>
                        </Grid>
                    </Highlight>

                    <Paragraph mt={3} style={{ fontSize: '16px', lineHeight: '24px' }}>
                        Try some other account starting with
                    </Paragraph>
                    <Heading style={{ fontSize: '16px', fontWeight: "800", paddingTop: 0, lineHeight: '24px' }}>
                        {localStorage["email"]}
                    </Heading>
                </div>
                <div className='full-button'>
                    <GoogleButton onClick={() => {
                        mixPanelTrackEvent('switch_google_account_click', {
                            type: "Click"
                        })
                        window.location.href = `${window.location.origin}/onboard?place_id=${localStorage["place_id"]}?status=2`;
                    }}>
                        <img src={google} alt='google' /> SWITCH GOOGLE ACCOUNT
                    </GoogleButton>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1
            }}>
                <HeaderEmpty style={{ background: '#F0F0F0', padding: '20px', paddingBottom: "14px" }} />
                {
                    localStorage.getItem('email') && localStorage.getItem('email')!='' ?
                    <div className="section-e" >
                        <Badge className="tooltip-badge" badgeContent={localStorage.getItem('email')} max={999}>
                        <img className='gmail' src={gmail} alt="gmail" />
                        <img className='error' src={error} alt="error" />
                    </Badge></div> :
                    <div className="section-e" >
                        <img className='gmail' src={gmail} alt="gmail" />
                        <img className='error' src={error} alt="error" />
                    </div>
                }
            </div>
        </div>
    );
}

export default WrongAccount;
