import PrimaryButton from "../utils/Button";
import ProgressBar from "../utils/Progress";
import Heading from "../utils/typography/Heading";
import SubHeading from "../utils/typography/SubHeading";
import Paragraph from "../utils/typography/Paragraph";
import { Container } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import base_url, { base_slack_channel } from "../backendUrl";
import React, { useState } from "react";
import reviewLink from "../assets/img/icons/reviewLink.svg";
import star from "../assets/img/icons/SendFollowUpMess.svg";
import { GlobalContext } from "../context/Global";
import { mixPanelTrackEvent } from "../utils/mixpanel";
import sendSlackNotification from "../utils/sendSlackNotification";
import postOnboardingStage from "../apis/postOnboardingStage";
import { CircularProgress } from "@mui/material";

const commonStyles = {
  width: "40px",
  height: "40px",
  alignContent: "flex-start",
};

function ConnectSquare({ stateChanger, new_state }) {
  const user_uid = localStorage["uid"];
  const APP_URL = `https://squareup.com/oauth2/authorize?client_id=sq0idp-__FWS8y0rmPCz3Jh96QrKQ&scope=ITEMS_READ+MERCHANT_PROFILE_READ+PAYMENTS_READ+APPOINTMENTS_READ+APPOINTMENTS_ALL_READ+CUSTOMERS_READ+CUSTOMERS_WRITE+ORDERS_READ+EMPLOYEES_READ+INVOICES_READ+APPOINTMENTS_ALL_READ+APPOINTMENTS_BUSINESS_SETTINGS_READ${
    user_uid ? `&state=${user_uid}` : ""
  }`;

  const WEB_URL = `https://squareup.com/oauth2/authorize?client_id=sq0idp-UPHzNPFfjcvHZgjbf_brmg&scope=ITEMS_READ+MERCHANT_PROFILE_READ+PAYMENTS_READ+APPOINTMENTS_READ+APPOINTMENTS_ALL_READ+CUSTOMERS_READ+CUSTOMERS_WRITE+ORDERS_READ+EMPLOYEES_READ+INVOICES_READ+APPOINTMENTS_ALL_READ+APPOINTMENTS_BUSINESS_SETTINGS_READ${
    user_uid ? `&state=${user_uid}` : ""
  }`;

  console.log(APP_URL);
  console.log(WEB_URL);
  const [squareOauthLink, setSquareOauthLink] = React.useState(
    window.location.href.includes("web.chrone") ? WEB_URL : APP_URL
  );
  const [globals] = React.useContext(GlobalContext);
  const [loading, setLoading] = React.useState(false);
  const fixedBottom = {
    position: "fixed",
    // left: (window.innerWidth - globals.width) / 2,
    bottom: 10,
    width: globals.width,
    backgroundColor: "#ffffff",
    color: "white",
    // textAlign: 'center',
    // zIndex: '9999'
  };
  const fetchSquareAuthorizeLink = async () => {
    debugger;
    let url = `${base_url}/get_square_oauth_url_react`;
    console.log(base_url);
    //For web: it will fetch different oauth url here (Square Application Name : Web Chrone)
    if (window.location.href.includes("web.chrone")) url += `?source=web`;
    const resp = await fetch(url);
    const result = await resp.json();
    console.log(result["url"]);
    debugger;
    setSquareOauthLink(`${result["url"]}&state=${localStorage["uid"]}`);
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const checkSquareProcess = async () => {
    setLoading(true);
    const merchantId = params["merchant_id"];
    if (merchantId) {
      // Send Slack notification for successful connection
      sendSlackNotification(
        base_slack_channel,
        `Square connected successfully for merchant ${merchantId}`
      );
    } else {
      const squareError = params["square_error"];
      if (squareError) {
        // Send Slack notification for error
        sendSlackNotification(
          base_slack_channel,
          `Square connection error: ${squareError}`
        );
      }
    }
    let uid = params["uid"]; // Get uid from params
    let place_id = params["place_id"];
    let email = params["email"];

    // Check if place_id is not falsy (null, undefined, empty string) and if it has any non-whitespace characters
    if (place_id && place_id.trim() && place_id.toLowerCase() !== "none") {
      localStorage.setItem("place_id", place_id);
    }

    // Check if uid is not falsy and if it has any non-whitespace characters
    if (uid && uid.trim() && uid.toLowerCase() !== "none") {
      localStorage.setItem("uid", uid); // Save uid in local storage
    }
    if (email && email.trim() && email.toLowerCase() !== "none") {
      localStorage.setItem("email", email); // Save uid in local storage
    }

    const squareCallback = params["square"];
    await postOnboardingStage(
      "services",
      localStorage["uid"],
      localStorage["email"]
    );
    //web.chrone.io/onboard/priming?square=true&merchant_id=MLHF3NDKDZ8QM&uid=de50b361-cb8f-4e1c-a009-d6f4eda7fa74&place_id=None#_=_
    if (squareCallback != undefined) {
      if (localStorage["square_oauth_started"]) {
        let square_oauth_started = localStorage["square_oauth_started"];
        let dt_start = new Date(square_oauth_started);
        let dt_now = new Date();

        let time_delta = dt_now - dt_start;

        localStorage.removeItem("square_oauth_started");

        if (time_delta > 0 && time_delta < 600000) {
          localStorage["squareOauth"] = "Completed";

          const resp = await fetch(`${base_url}/square_gmb_mapping`, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              place_id: localStorage["place_id"],
              uid: localStorage["uid"],
              merchant_id: merchantId,
              only_slack: false,
            }),
          });
          const result = await resp.json();
          await ("services", localStorage["uid"], localStorage["email"]);
          if (result.res == "mapped") {
            console.log("Success", result);
            // stateChanger(3);
            window.location.href = localStorage["uid"]
              ? `/services?uid=${localStorage["uid"]}`
              : "/services";
          } else {
            console.log("Error", result);
            // stateChanger(3);
            window.location.href = localStorage["uid"]
              ? `/services?uid=${localStorage["uid"]}`
              : "/services";
          }
        } else {
          const resp = await fetch(`${base_url}/square_gmb_mapping`, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              place_id: localStorage["place_id"],
              uid: localStorage["uid"],
              merchant_id: merchantId,
              only_slack: false,
            }),
          });
          const result = await resp.json();
          if (result.res == "mapped") {
            console.log("Success", result);
            // stateChanger(3);
            window.location.href = `${window.location.origin}/review-consent`;
          } else {
            console.log("Error", result);
            // stateChanger(3);
            window.location.href = localStorage["uid"]
              ? `/services?uid=${localStorage["uid"]}`
              : "/services";
          }
        }
      } else {
        console.log("square_oauth_started not in localStorage");
        window.location.href = localStorage["uid"]
          ? `/services?uid=${localStorage["uid"]}`
          : "/services";
        // stateChanger(3);
      }
    } else {
      console.log("squareCallback not in params");
      // stateChanger(3);
      window.location.href = localStorage["uid"]
        ? `/services?uid=${localStorage["uid"]}`
        : "/services";
    }
    setLoading(true);
  };

  const onHandleSkip = async () => {
    mixPanelTrackEvent("no_square_use_click", {
      type: "Click",
    });
    // stateChanger(3);
    await postOnboardingStage(
      "services",
      localStorage["uid"],
      localStorage["email"]
    );
    await sendSlackNotification(
      base_slack_channel,
      `I don't use Square / Skip Clicked`
    );
    window.location.href = localStorage["uid"]
      ? `/services?uid=${localStorage["uid"]}`
      : "/services";
  };
  React.useEffect(() => {
    debugger;
    if (!params["square"]) {
      fetchSquareAuthorizeLink();
    } else {
      checkSquareProcess();
    }

    mixPanelTrackEvent("connect_square_page_open", {
      type: "Page Open",
    });
  }, []);

  const squareOauth = () => {
    console.log("connect square clicked");
    localStorage["square_oauth_started"] = new Date().toISOString();
    window.location.href = squareOauthLink;
  };
  if (loading) return <CircularProgress />;
  return (
    <div>
      <Container
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          // height: `${window.innerHeight * 0.8}px`,
          overflowX: "scroll",
        }}>
        <Heading paddingTop={"32px"} paddingBottom={"8px"}>
          {" "}
          Connect Square to automate reviews posting{" "}
        </Heading>
        <Paragraph style={{ fontSize: "16px", lineHeight: "24px" }}>
          With this connection Chrone can :
        </Paragraph>
        <Box mt={4} sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={2} style={{ alignContent: "flex-start" }}>
                  <Box sx={{ ...commonStyles }}>
                    <img style={{ ...commonStyles }} src={reviewLink} />{" "}
                  </Box>
                </Grid>
                <Grid sx={{ alignContent: "flex-start" }} container item xs={9}>
                  <SubHeading> Send review link to clients </SubHeading>
                  <Paragraph>
                    Whenever a client completes payment, we send them
                    personalised review link{" "}
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid container>
                <Grid item xs={2} style={{ alignContent: "flex-start" }}>
                  <Box sx={{ ...commonStyles }}>
                    {" "}
                    <img style={{ ...commonStyles }} src={star} />{" "}
                  </Box>
                </Grid>
                <Grid sx={{ alignContent: "flex-start" }} container item xs={9}>
                  <SubHeading> Send follow up message </SubHeading>
                  <Paragraph>
                    {" "}
                    In case a client misses leaving a review, we send them a
                    follow up message
                  </Paragraph>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Paragraph mt={4} style={{ fontSize: "16px", lineHeight: "24px" }}>
          All this without your effort.
        </Paragraph>
      </Container>

      {/* <Box style={{ ...fixedBottom }}>
        <Container style={{ padding: 0 }}>
          <Grid
            sx={{
              background: "#fff",
              position: "absolute",
              bottom: "0",
              padding: "20px 20px",
            }}
            container
          >
            <Grid
              sx={{ alignItems: "center", display: "flex", cursor: "pointer" }}
              item
              xs={6}
            >
              <div
                onClick={() => {
                  mixPanelTrackEvent("no_square_use_click", {
                    type: "Click",
                  });
                  // stateChanger(3);
                  window.location.href = '/services';
                }}
              >
                <Paragraph
                  style={{
                    color: "#000000",
                    fontWeight: "800",
                    fontSize: "14px",
                  }}
                >
                  I don't use Square
                </Paragraph>
              </div>
            </Grid>
            <Grid
              sx={{ alignContent: "center", cursor: "pointer" }}
              container
              item
              xs={6}
            >
              <PrimaryButton
                disabled={false}
                style={{ fontSize: "14px" }}
                onClick={() => {
                  mixPanelTrackEvent("connect_square_click", {
                    type: "Click",
                  });
                  squareOauth();
                }}
              >
                CONNECT SQUARE
              </PrimaryButton>
            </Grid>
          </Grid>
        </Container>
          
      </Box> */}
      <div
        style={{
          bottom: 0,
          position: "fixed",
          width: "100%",
          left: 0,
          padding: "0px 20px",
          right: 0,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <div style={{ width: window.innerWidth > 768 ? "450px" : "100%" }}>
          <ProgressBar value={30} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              padding: "0px 20px",
            }}>
            <Grid
              sx={{ alignItems: "center", display: "flex", cursor: "pointer" }}
              item
              xs={6}>
              <div
                onClick={onHandleSkip}
                style={{
                  color: "#000000",
                  fontWeight: "800",
                  fontSize: "14px",
                  cursor: "pointer",
                  padding: "1rem 0",
                }}>
                <Paragraph
                  style={{
                    color: "#000000",
                    fontWeight: "800",
                    fontSize: "14px",
                  }}>
                  Skip
                </Paragraph>
              </div>
            </Grid>
            <Grid
              sx={{
                alignContent: "center",
                cursor: "pointer",
                maxWidth: "70%",
              }}
              container
              item
              xs={6}>
              <PrimaryButton
                disabled={false}
                style={{
                  fontSize: "14px",
                  padding: "16px 12px",
                }}
                onClick={async () => {
                  mixPanelTrackEvent("connect_square_click", {
                    type: "Click",
                  });
                  await sendSlackNotification(
                    base_slack_channel,
                    `Connect Square Clicked`
                  );
                  squareOauth();
                }}>
                CONNECT SQUARE
              </PrimaryButton>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectSquare;
