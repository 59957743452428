import PrimaryButton from "../utils/Button";
import Heading from "../utils/typography/Heading";
import priming_finish from "../assets/img/priming_finish.webp";
import HeaderBack from "../utils/HeaderBack";
import Badge from "@mui/material/Badge";
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../context/Global";
import { mixPanelTrackEvent } from "../utils/mixpanel";

function PrimingFinish({ stateChanger, new_state }) {
  const [globals] = useContext(GlobalContext);
  const change = () => stateChanger(new_state);

  useEffect(() => {
    mixPanelTrackEvent("services_priming_screen_open", {
      type: "Page Open",
    });
  }, []);

  return (
    <div
      style={{
        height: globals.height,
        display: "flex",
        flexDirection: "column-reverse",
      }}>
      <div className="got-it-button">
        <PrimaryButton
          onClick={() => {
            mixPanelTrackEvent("services_priming_success_click", {
              type: "Click",
            });
            window.location.href = localStorage["uid"]
              ? `/services?uid=${localStorage["uid"]}`
              : "/services";
          }}
          style={{ margin: "auto" }}
          disabled={false}>
          GOT IT
        </PrimaryButton>
      </div>
      <div
        className="hair"
        style={{
          backgroundImage: `url(${priming_finish})`,
          backgroundSize: "contain",
          flexGrow: 1,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      />
      <div className="side-padding">
        <Heading style={{ paddingTop: 0 }}>
          Chrone gets you leads for the services you offer
        </Heading>
      </div>
      <HeaderBack back={change} backColor={"#FFFFFF"} />
    </div>
  );
}

export default PrimingFinish;
