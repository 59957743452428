import { useState, useEffect, useContext } from "react";
import Platform from "./Platform";
import ServicesList from "./ServicesList";
import NewServiceDetail from "./NewServiceDetail";
import SearchService from "./SearchService";
import LoadingServices from "./LoadingServices";
import { ServicesContext, ServicesContextProvider } from "./context/context";
import NewServiceAdd from "./ServiceAdd";
import { useTransition, animated } from "react-spring";
import { GlobalContext } from "../context/Global";

function Services() {
  const [showSnackbar, setSnackbar] = useState(false);
  const [state, setState] = useState(1);
  const [services, setServices] = useState([]);
  const [service, setService] = useState("");
  const [prev, setPrev] = useState(1);
  const [globals] = useContext(GlobalContext);

  const [serviceState, setServiceStates] = useContext(ServicesContext);

  const screen = serviceState.screen;

  const pageTransitions = useTransition(screen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 200,
    },
  });

  const otherBookingPlatforms = async () => {
    setServiceStates({
      action: "change_screen",
      screen_no: 4,
    });
  };

  const noOnlineBooking = async () => {
    setServiceStates({
      action: "change_screen",
      screen_no: 2,
    });
  };

  const pages = {
    0: <Platform />,
    1: <LoadingServices />,
    2: <ServicesList />,
    4: <NewServiceAdd />,
    5: <NewServiceDetail />,
    6: <SearchService />,
  };

  // State 0 - Select booking platform screen
  // State 1 - Loading screen (doing BE process)
  // State 2 - Services list screen
  // State 4 - Enter booking link screen

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uid = params.get("uid");
    if (uid) {
      localStorage.setItem("uid", uid);
    }
  }, []); // Runs once on component mount

  return (
    <div>
      {pageTransitions((props, idx) => {
        return (
          <animated.div
            style={{
              ...props,
              width: globals.width,
              height: globals.height,
              top: 0,
              left: (window.innerWidth - globals.width) / 2,
            }}>
            {pages[idx]}
          </animated.div>
        );
      })}
    </div>
  );
}

export default Services;
