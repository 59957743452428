import PrimaryButton from '../utils/Button';
import ProgressBar from '../utils/Progress';
import Heading from '../utils/typography/Heading';
import Paragraph from '../utils/typography/Paragraph';
import { Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import sendSlackNotification from '../utils/sendSlackNotification';
import { base_slack_channel } from '../backendUrl';


function ConnectInsta({ stateChanger, new_state }) {
  return (
    <div>
      <Container>
        <Heading> Connect business instagram account to automate photos posting </Heading>
        <Paragraph style={{ fontSize: '16px', lineHeight: '24px' }}>
          With this connection Chrone can automatically fetch photos from your Instagram and post it on google profile & your website.
        </Paragraph>

      </Container>
      <ProgressBar mt={5} value={100} />
      <Container >
        <Grid sx={{ margin: '24px auto' }} container >
          <Grid sx={{ alignItems: 'center', display: 'flex' }} item xs={4}>
            <div onClick={async () => {
              await sendSlackNotification(base_slack_channel, `Skip insta clicked`);
              window.location.href = '/services';
            }}>
              <Paragraph style={{ color: '#000000', fontWeight: '800' }}>
                Skip for now
              </Paragraph>
            </div>
          </Grid>
          <Grid sx={{ alignContent: 'center' }} container item xs={8}>
            <PrimaryButton disabled={false} style={{ fontSize: '14px' }} onClick={() => stateChanger(new_state)}>CONNECT INSTAGRAM</PrimaryButton>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default ConnectInsta;
