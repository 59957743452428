import React, { useContext } from "react";
import PrimaryButton from "../utils/Button";
import { CounterContext } from "./context/counter-context";

export default function CounterButtons() {
  const [count, setCount] = useContext(CounterContext);

  const increment = () => {
    setCount({
      action: "update_counter",
      data: 1
    });
    setCount({
      action: "update_counter",
      data: 3
    });
  };

  const decrement = () => {
    setCount({
      action: "update_counter",
      data: -1
    });
  };

  return (
    <div>
      <div>
        <PrimaryButton disabled={false} color="green" onClick={increment}>
          Add
        </PrimaryButton>
        <PrimaryButton disabled={false} color="red" onClick={decrement}>
          Minus
        </PrimaryButton>
      </div>
    </div>
  );
}