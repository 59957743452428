import React from "react";

import { CounterContextProvider } from "./context/counter-context";
import CounterDisplay from "./counter-display";
import CounterButtons from "./counter-buttons";
import ServicesDisplay from "./services-display";
import ServiceButtons from "./pathA/pathB/service-buttons";

export default function CounterView() {
  return (
    <CounterContextProvider>
      <div style={{
        display: "flex",
        flexDirection: "column"
      }}>
        <h3>Counter</h3>
        <div textAlign="center">
          <CounterDisplay />
          <CounterButtons />
        </div>

        <h3>Services</h3>
        <div textAlign="center">
          <ServicesDisplay />
          <ServiceButtons />
        </div>
      </div>
    </CounterContextProvider>
  );
}