import React, { useContext } from "react";
import { CounterContext } from "./context/counter-context";

export default function ServicesDisplay() {
  const [state] = useContext(CounterContext);
  console.log(state.services);
  const x = state.services.map((i)=>JSON.stringify(i))
  return (
    <>
      <div>Services</div>
      <div>{Date().toString()}</div>
      <div>{state.services.length ?? 0}</div>
      <div>{x}</div>
    </>
  );
}