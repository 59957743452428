import { Typography } from '@mui/material';
import { GlobalContext } from '../../context/Global';
import { useContext } from 'react';


const Heading = (props) => {
    const [globals] = useContext(GlobalContext);

    const headingStyle = {
        fontFamily: 'Red Hat Display bold',
        fontStyle: 'normal',
        fontWeight: `${globals.browser=='Apple Safari'?" 400":"700"}`,
        fontSize: '24px',
        lineHeight: '32px',
        color: '#0C1414',
        textAlign : 'left',
        padding : '20px 0',
        letterSpacing: 0,
        paddingTop: props.paddingTop ?? "unset",
        paddingBottom: props.paddingBottom ?? "unset",
        textAlign:"center"
    }

    return(
        <Typography mt={props.mt} mx={props.mx} sx={{...headingStyle, ...props.style, }}  variant="h1" component="h1" >
            {props.children}
        </Typography>
    );
}

export default Heading;