import axios from "axios";
import base_url from "../backendUrl";
import sendSlackNotification from "../utils/sendSlackNotification";

const postOnboardingStage = async (onboardingStage, uid, email) => {
  let data = JSON.stringify({
    uid: uid,
    email: email,
    onboarding_stage: onboardingStage,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${base_url}/onboarding-log`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const resp = await axios.request(config);

    await sendSlackNotification(
      "temp-channel",
      `posted step: ${onboardingStage}\n ${uid}`
    );
  } catch (e) {
    await sendSlackNotification(
      "temp-channel",
      `Error: Couldn't post step: ${onboardingStage}-${uid} \n ${e}`
    );
  }
};

export default postOnboardingStage;
