import React from "react";

import CounterView from "./counter-view";

export default function ContextApp() {
  return (
    <div style={{
      display: "flex",
      flexDirection: "column"
    }}>
      <h1>React Hooks Context Demo</h1>
      <div>
        <p>{Date().toString()}</p>
      </div>

      <CounterView />
    </div>
  );
}